<template>
  <div class="card col bigcard">
    <div class="card-body">
      <table class="table table-striped">
              <thead><th colspan="8"><h5 class="card-title">Lebensqualitätsfragebogen (EORTC QLQ-C30)</h5></th></thead>
      <thead>
        <th colspan="8" class="margins">Wir sind an einigen Angaben interessiert, die Sie und Ihre Gesundheit
        betreffen. Bitte beantworten Sie die folgenden Fragen selbst, indem Sie
        die Zahl ankreuzen, die am besten auf Sie zutrifft. Es gibt keine
        "richtigen" oder "falschen" Antworten. Ihre Angaben werden streng
        vertraulich behandelt.</th>
      </thead>
        <thead>
            <th scope="col" colspan="4"></th>
            <th scope="col" class="text-center" style="width: 100px">über-haupt nicht</th>
            <th scope="col" class="text-center" style="width: 100px">wenig</th>
            <th scope="col" class="text-center" style="width: 100px">ziemlich</th>
            <th scope="col" class="text-center" style="width: 100px">sehr</th>
        </thead>
        <tbody>
          <tr :class="VALIDATION['q1ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              1. Bereitet es Ihnen Schwierigkeiten, sich körperlich anzustrengen?
              (z.B. eine schwere Einkaufstasche oder einen Koffer zu tragen)
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(1)"
                id="q1_option1"
                v-model="c30_1_value"
                :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q1_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                 class="btn-check"
                :value="2"
                :name="questionRadioName(1)"
                    id="q1_option2"
                v-model="c30_1_value"
                :disabled="!MEASUREMENT.can_edit"
              />
                            <label class="btn btn-outline-primary btn-lg" for="q1_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                :value="3"
                 class="btn-check"
                :name="questionRadioName(1)"
                 id="q1_option3"
                v-model="c30_1_value"
                :disabled="!MEASUREMENT.can_edit"
              />
                             <label class="btn btn-outline-primary btn-lg" for="q1_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                 class="btn-check"
                :value="4"
                :name="questionRadioName(1)"
                  id="q1_option4"
                v-model="c30_1_value"
                :disabled="!MEASUREMENT.can_edit"
              />
                   <label class="btn btn-outline-primary btn-lg" for="q1_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q2ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              2. Bereitet es Ihnen Schwierigkeiten, einen <u>längeren</u> Spaziergang
              zu machen?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(2)"
                id="q2_option1"
                v-model="c30_2_value"
                :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q2_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(2)"
                 id="q2_option2"
                v-model="c30_2_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q2_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(2)"
                id="q2_option3"
                v-model="c30_2_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q2_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(2)"
                id="q2_option4"
                v-model="c30_2_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q2_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q3ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              3. Bereitet es Ihnen Schwierigkeiten, eine <u>kurze</u> Strecke außer
              Haus zu gehen?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(3)"
                id="q3_option1"
                v-model="c30_3_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q3_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(3)"
                id="q3_option2"
                v-model="c30_3_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q3_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(3)"
                id="q3_option3"
                v-model="c30_3_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q3_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(3)"
                id="q3_option4"
                v-model="c30_3_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q3_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q4ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              4. Müssen Sie tagsüber im Bett liegen oder in einem Sessel sitzen?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(4)"
                id="q4_option1"
                v-model="c30_4_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q4_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(4)"
                id="q4_option2"
                v-model="c30_4_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
                 <label class="btn btn-outline-primary btn-lg" for="q4_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(4)"
                id="q4_option3"
                v-model="c30_4_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
                <label class="btn btn-outline-primary btn-lg" for="q4_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(4)"
                id="q4_option4"
                v-model="c30_4_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
                   <label class="btn btn-outline-primary btn-lg" for="q4_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q5ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              5. Brauchen Sie Hilfe beim Essen, Anziehen, Waschen oder Benutzen
              der Toilette?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(5)"
                id="q5_option1"
                v-model="c30_5_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q5_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(5)"
                id="q5_option2"
                v-model="c30_5_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q5_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(5)"
                id="q5_option3"
                v-model="c30_5_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q5_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(5)"
                id="q5_option4"
                v-model="c30_5_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q5_option4">4</label>
            </td>
          </tr>
        </tbody>
        <thead >
            <th scope="col" colspan="4" class="align-middle">Die folgenden Fragen 6 bis 30 beziehen sich auf Ihren Zustand <u>während der letzten Woche:</u></th>
            <th scope="col" class="text-center" style="width: 100px">über-haupt nicht</th>
            <th scope="col" class="text-center" style="width: 100px">wenig</th>
            <th scope="col" class="text-center" style="width: 100px">ziemlich</th>
            <th scope="col" class="text-center" style="width: 100px">sehr</th>
        </thead>
        <tbody>
          <tr :class="VALIDATION['q6ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              6. Waren Sie bei Ihrer Arbeit oder bei anderen tagtäglichen
              Beschäftigungen eingeschränkt?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(6)"
                id="q6_option1"
                v-model="c30_6_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q6_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(6)"
                id="q6_option2"
                v-model="c30_6_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q6_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(6)"
                id="q6_option3"
                v-model="c30_6_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q6_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(6)"
                id="q6_option4"
                v-model="c30_6_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q6_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q7ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              7. Waren Sie bei Ihren Hobbys oder anderen Freizeitbeschäftigungen
              eingeschränkt?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(7)"
                id="q7_option1"
                v-model="c30_7_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q7_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(7)"
                id="q7_option2"
                v-model="c30_7_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q7_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(7)"
                id="q7_option3"
                v-model="c30_7_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
                <label class="btn btn-outline-primary btn-lg" for="q7_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(7)"
                id="q7_option4"
                v-model="c30_7_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
                <label class="btn btn-outline-primary btn-lg" for="q7_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q8ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">8. Waren Sie kurzatmig?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(8)"
                id="q8_option1"
                v-model="c30_8_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q8_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(8)"
                id="q8_option2"
                v-model="c30_8_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q8_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(8)"
                id="q8_option3"
                v-model="c30_8_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q8_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(8)"
                id="q8_option4"
                v-model="c30_8_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q8_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q9ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">9. Hatten Sie Schmerzen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(9)"
                id="q9_option1"
                v-model="c30_9_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q9_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(9)"
                id="q9_option2"
                v-model="c30_9_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q9_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(9)"
                id="q9_option3"
                v-model="c30_9_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q9_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(9)"
                id="q9_option4"
                v-model="c30_9_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q9_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q10ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">10. Mussten Sie sich ausruhen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(10)"
                id="q10_option1"
                v-model="c30_10_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q10_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(10)"
                id="q10_option2"
                v-model="c30_10_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q10_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(10)"
                id="q10_option3"
                v-model="c30_10_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q10_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(10)"
                id="q10_option4"
                v-model="c30_10_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q10_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q11ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">11. Hatten Sie Schlafstörungen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(11)"
                id="q11_option1"
                v-model="c30_11_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q11_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(11)"
                id="q11_option2"
                v-model="c30_11_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q11_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(11)"
                id="q11_option3"
                v-model="c30_11_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q11_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(11)"
                id="q11_option4"
                v-model="c30_11_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q11_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q12ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">12. Fühlten Sie sich schwach?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(12)"
                id="q12_option1"
                v-model="c30_12_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q12_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(12)"
                id="q12_option2"
                v-model="c30_12_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q12_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(12)"
                id="q12_option3"
                v-model="c30_12_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q12_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(12)"
                id="q12_option4"
                v-model="c30_12_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q12_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q13ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">13. Hatten Sie Appetitmangel?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(13)"
                id="q13_option1"
                v-model="c30_13_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q13_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(13)"
                id="q13_option2"
                v-model="c30_13_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q13_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(13)"
                id="q13_option3"
                v-model="c30_13_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q13_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(13)"
                id="q13_option4"
                v-model="c30_13_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q13_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q14ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">14. War Ihnen übel?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(14)"
                id="q14_option1"
                v-model="c30_14_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q14_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(14)"
                id="q14_option2"
                v-model="c30_14_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q14_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(14)"
                id="q14_option3"
                v-model="c30_14_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q14_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(14)"
                id="q14_option4"
                v-model="c30_14_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q14_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q15ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">15. Haben Sie erbrochen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(15)"
                id="q15_option1"
                v-model="c30_15_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q15_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(15)"
                id="q15_option2"
                v-model="c30_15_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q15_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(15)"
                id="q15_option3"
                v-model="c30_15_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q15_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(15)"
                id="q15_option4"
                v-model="c30_15_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q15_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q16ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">16. Hatten Sie Verstopfung?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(16)"
                id="q16_option1"
                v-model="c30_16_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q16_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(16)"
                id="q16_option2"
                v-model="c30_16_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q16_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(16)"
                id="q16_option3"
                v-model="c30_16_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q16_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(16)"
                id="q16_option4"
                v-model="c30_16_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q16_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q17ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">17. Hatten Sie Durchfall?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(17)"
                id="q17_option1"
                v-model="c30_17_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q17_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(17)"
                id="q17_option2"
                v-model="c30_17_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q17_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(17)"
                id="q17_option3"
                v-model="c30_17_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q17_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(17)"
                id="q17_option4"
                v-model="c30_17_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q17_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q18ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">18. Waren Sie müde?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(18)"
                id="q18_option1"
                v-model="c30_18_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q18_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(18)"
                id="q18_option2"
                v-model="c30_18_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q18_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(18)"
                id="q18_option3"
                v-model="c30_18_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q18_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(18)"
                id="q18_option4"
                v-model="c30_18_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q18_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q19ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              19. Fühlten Sie sich durch Schmerzen in Ihrem alltäglichen Leben
              beeinträchtigt?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(19)"
                id="q19_option1"
                v-model="c30_19_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q19_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(19)"
                id="q19_option2"
                v-model="c30_19_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q19_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(19)"
                id="q19_option3"
                v-model="c30_19_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q19_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(19)"
                id="q19_option4"
                v-model="c30_19_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q19_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q20ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              20. Hatten Sie Schwierigkeiten, sich auf etwas zu konzentrieren,
              z.B. auf das Zeitunglesen oder das Fernsehen?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(20)"
                id="q20_option1"
                v-model="c30_20_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q20_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(20)"
                id="q20_option2"
                v-model="c30_20_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q20_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(20)"
                id="q20_option3"
                v-model="c30_20_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q20_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(20)"
                id="q20_option4"
                v-model="c30_20_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q20_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q21ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">21. Fühlten Sie sich angespannt?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(21)"
                id="q21_option1"
                v-model="c30_21_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q21_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(21)"
                id="q21_option2"
                v-model="c30_21_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q21_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(21)"
                id="q21_option3"
                v-model="c30_21_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q21_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(21)"
                id="q21_option4"
                v-model="c30_21_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q21_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q22ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">22. Haben Sie sich Sorgen gemacht?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(22)"
                id="q22_option1"
                v-model="c30_22_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q22_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(22)"
                id="q22_option2"
                v-model="c30_22_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q22_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(22)"
                id="q22_option3"
                v-model="c30_22_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q22_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(22)"
                id="q22_option4"
                v-model="c30_22_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q22_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q23ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">23. Waren Sie reizbar?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(23)"
                id="q23_option1"
                v-model="c30_23_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q23_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(23)"
                id="q23_option2"
                v-model="c30_23_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q23_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(23)"
                id="q23_option3"
                v-model="c30_23_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q23_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(23)"
                id="q23_option4"
                v-model="c30_23_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q23_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q24ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">24. Fühlten Sie sich niedergeschlagen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(24)"
                id="q24_option1"
                v-model="c30_24_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q24_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(24)"
                id="q24_option2"
                v-model="c30_24_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q24_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(24)"
                id="q24_option3"
                v-model="c30_24_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q24_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(24)"
                id="q24_option4"
                v-model="c30_24_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q24_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q25ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">25. Hatten Sie Schwierigkeiten, sich an Dinge zu erinnern?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(25)"
                id="q25_option1"
                v-model="c30_25_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q25_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(25)"
                id="q25_option2"
                v-model="c30_25_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q25_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(25)"
                id="q25_option3"
                v-model="c30_25_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q25_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(25)"
                id="q25_option4"
                v-model="c30_25_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q25_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q26ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              26. Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung
              Ihr <u>Familienleben</u> beeinträchtigt?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(26)"
                id="q26_option1"
                v-model="c30_26_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q26_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(26)"
                id="q26_option2"
                v-model="c30_26_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q26_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(26)"
                id="q26_option3"
                v-model="c30_26_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q26_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(26)"
                id="q26_option4"
                v-model="c30_26_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q26_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q27ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              27. Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung
              Ihr Zusammensein oder Ihre gemeinsamen Unternehmungen <u>mit anderen
              Menschen</u> beeinträchtigt?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(27)"
                id="q27_option1"
                v-model="c30_27_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q27_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(27)"
                id="q27_option2"
                v-model="c30_27_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q27_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(27)"
                id="q27_option3"
                v-model="c30_27_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q27_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(27)"
                id="q27_option4"
                v-model="c30_27_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q27_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q28ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              28. Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung
              für Sie finanzielle Schwierigkeiten mit sich gebracht?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(28)"
                id="q28_option1"
                v-model="c30_28_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q28_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(28)"
                id="q28_option2"
                v-model="c30_28_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q28_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(28)"
                id="q28_option3"
                v-model="c30_28_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q28_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(28)"
                id="q28_option4"
                v-model="c30_28_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q28_option4">4</label>
            </td>
          </tr>
        </tbody>
        <thead >
            <th scope="col" class="align-middle">Bitte kreuzen Sie bei den folgenden Fragen die Zahl zwischen 1 und
            7 an, die am besten auf Sie zutrifft.</th>
            <th scope="col" class="text-center" style="width: 100px">sehr schlecht</th>
            <th scope="col" class="text-center" style="width: 100px"></th>
            <th scope="col" class="text-center" style="width: 100px"></th>
            <th scope="col" class="text-center" style="width: 100px"></th>
            <th scope="col" class="text-center" style="width: 100px"></th>
            <th scope="col" class="text-center" style="width: 100px"></th>
            <th scope="col" class="text-center" style="width: 100px">ausge-zeichnet</th>
  
        </thead>
        <tbody>
          <tr :class="VALIDATION['q29ok'] ? 'table-primary' : 'table-danger'">
          <td>29. Wie würden Sie insgesamt Ihren
          <u>Gesundheitszustand</u>
          während der letzten Woche einschätzen?</td>
            <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="1"
                    :name="questionRadioName(29)"
                    id="q29_option1"
                    v-model="c30_29_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option1">1</label>
</td>
  <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="2"
                    :name="questionRadioName(29)"
                    id="q29_option2"
                    v-model="c30_29_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option2">2</label>
                </td>
                  <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="3"
                    :name="questionRadioName(29)"
                    id="q29_option3"
                    v-model="c30_29_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option3">3</label>
                </td>
       <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="4"
                    :name="questionRadioName(29)"
                    id="q29_option4"
                    v-model="c30_29_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option4">4</label>
                </td>
                 <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="5"
                    :name="questionRadioName(29)"
                    id="q29_option5"
                    v-model="c30_29_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option5">5</label>
                </td>
                <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="6"
                    :name="questionRadioName(29)"
                    id="q29_option6"
                    v-model="c30_29_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option6">6</label>
                </td>
        <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="7"
                    :name="questionRadioName(29)"
                    id="q29_option7"
                    v-model="c30_29_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option7">7</label>
                </td>
              </tr>
        <tr :class="VALIDATION['q30ok'] ? 'table-primary' : 'table-danger'">
          <td>
          30. Wie würden Sie insgesamt Ihre
          <u>Lebensqualität</u>
          während der letzten Woche einschätzen?
          </td>
       
                <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="1"
                    :name="questionRadioName(30)"
                    id="q30_option1"
                    v-model="c30_30_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option1">1</label>
                </td>
                         <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="2"
                    :name="questionRadioName(30)"
                    id="q30_option2"
                    v-model="c30_30_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option2">2</label>
                </td>
                         <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="3"
                    :name="questionRadioName(30)"
                    id="q30_option3"
                    v-model="c30_30_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option3">3</label>
                </td>
                     <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="4"
                    :name="questionRadioName(30)"
                    id="q30_option4"
                    v-model="c30_30_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option4">4</label>
                </td>
                  <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="5"
                    :name="questionRadioName(30)"
                    id="q30_option5"
                    v-model="c30_30_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option5">5</label>
                </td>
            <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="6"
                    :name="questionRadioName(30)"
                    id="q30_option6"
                    v-model="c30_30_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option6">6</label>
                </td>
                       <td class="text-center">
                  <input
                    type="radio"
                    class="btn-check"
                    value="7"
                    :name="questionRadioName(30)"
                    id="q30_option7"
                    v-model="c30_30_value"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                   <label class="btn btn-outline-primary btn-lg" for="q30_option7">7</label>
                </td>
              </tr>
        </tbody>
  
      <thead><th><h5 colspan="8" class="card-title" style="margin-top:20px">Lebensqualitätsfragebogen (EORTC QLQ-LC29)</h5></th></thead>
      <thead >
         
            <th scope="col" colspan="4" class="align-middle">Patienten berichten manchmal die folgenden Symptome oder Probleme. Bitte
        geben Sie an, in welchem Ausmaß Sie diese Symptome oder Probleme
        <br><u>während der letzten Woche</u> erlebt haben. Markieren Sie bitte die
        Zahl, die am besten auf Sie zutrifft.</th>
                <th scope="col" class="text-center" style="width: 100px">über-haupt nicht</th>
            <th scope="col" class="text-center" style="width: 100px">wenig</th>
            <th scope="col" class="text-center" style="width: 100px">ziemlich</th>
            <th scope="col" class="text-center" style="width: 100px">sehr</th>
        
        </thead>
        <tbody>
          <tr :class="VALIDATION['q31ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">31. Haben Sie gehustet?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(31)"
                id="q31_option1"
                v-model="lc29_31_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q31_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(31)"
                id="q31_option2"
                v-model="lc29_31_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q31_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(31)"
                id="q31_option3"
                v-model="lc29_31_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q31_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(31)"
                id="q31_option4"
                v-model="lc29_31_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q31_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q32ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">32. Haben Sie Blut gehustet?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(32)"
                id="q32_option1"
                v-model="lc29_32_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q32_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(32)"
                id="q32_option2"
                v-model="lc29_32_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q32_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(32)"
                id="q32_option3"
                v-model="lc29_32_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q32_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(32)"
                id="q32_option4"
                v-model="lc29_32_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q32_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q33ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">33. Waren Sie unter Ruhebedingungen kurzatmig?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(33)"
                id="q33_option1"
                v-model="lc29_33_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q33_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(33)"
                id="q33_option2"
                v-model="lc29_33_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q33_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(33)"
                id="q33_option3"
                v-model="lc29_33_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q33_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(33)"
                id="q33_option4"
                v-model="lc29_33_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q33_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q34ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">34. Waren Sie beim Gehen kurzatmig?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(34)"
                id="q34_option1"
                v-model="lc29_34_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q34_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(34)"
                id="q34_option2"
                v-model="lc29_34_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q34_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(34)"
                id="q34_option3"
                v-model="lc29_34_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q34_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(34)"
                id="q34_option4"
                v-model="lc29_34_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q34_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q35ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">35. Waren Sie beim Treppensteigen kurzatmig?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(35)"
                id="q35_option1"
                v-model="lc29_35_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q35_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(35)"
                id="q35_option2"
                v-model="lc29_35_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q35_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(35)"
                id="q35_option3"
                v-model="lc29_35_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q35_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(35)"
                id="q35_option4"
                v-model="lc29_35_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q35_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q36ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">36. War Ihr Mund oder Ihre Zunge wund?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(36)"
                id="q36_option1"
                v-model="lc29_36_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q36_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(36)"
                id="q36_option2"
                v-model="lc29_36_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q36_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(36)"
                id="q36_option3"
                v-model="lc29_36_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q36_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(36)"
                id="q36_option4"
                v-model="lc29_36_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q36_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q37ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">37. Hatten Sie Probleme beim Schlucken?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(37)"
                id="q37_option1"
                v-model="lc29_37_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q37_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(37)"
                id="q37_option2"
                v-model="lc29_37_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q37_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(37)"
                id="q37_option3"
                v-model="lc29_37_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q37_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(37)"
                id="q37_option4"
                v-model="lc29_37_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q37_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q38ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">38. Hatten Sie Kribbeln in den Händen oder Füßen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(38)"
                id="q38_option1"
                v-model="lc29_38_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q38_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(38)"
                id="q38_option2"
                v-model="lc29_38_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q38_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(38)"
                id="q38_option3"
                v-model="lc29_38_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q38_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(38)"
                id="q38_option4"
                v-model="lc29_38_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q38_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q39ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">39. Hatten Sie Haarausfall?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(39)"
                id="q39_option1"
                v-model="lc29_39_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q39_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(39)"
                id="q39_option2"
                v-model="lc29_39_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q39_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(39)"
                id="q39_option3"
                v-model="lc29_39_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q39_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(39)"
                id="q39_option4"
                v-model="lc29_39_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q39_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q40ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">40. Hatten Sie Schmerzen im Brustkorb?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(40)"
                id="q40_option1"
                v-model="lc29_40_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q40_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(40)"
                id="q40_option2"
                v-model="lc29_40_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q40_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(40)"
                id="q40_option3"
                v-model="lc29_40_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q40_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(40)"
                id="q40_option4"
                v-model="lc29_40_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q40_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q41ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              41. Hatten Sie Schmerzen in Ihrem Arm oder in Ihrer Schulter?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(41)"
                id="q41_option1"
                v-model="lc29_41_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q41_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(41)"
                id="q41_option2"
                v-model="lc29_41_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q41_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(41)"
                id="q41_option3"
                v-model="lc29_41_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q41_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(41)"
                id="q41_option4"
                v-model="lc29_41_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q41_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q42ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">42. Hatten Sie an anderen Stellen des Körpers Schmerzen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(42)"
                id="q42_option1"
                v-model="lc29_42_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q42_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(42)"
                id="q42_option2"
                v-model="lc29_42_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q42_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(42)"
                id="q42_option3"
                v-model="lc29_42_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q42_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(42)"
                id="q42_option4"
                v-model="lc29_42_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q42_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q43ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">43. Hatten Sie allergische Reaktionen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(43)"
                id="q43_option1"
                v-model="lc29_43_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q43_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(43)"
                id="q43_option2"
                v-model="lc29_43_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q43_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(43)"
                id="q43_option3"
                v-model="lc29_43_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q43_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(43)"
                id="q43_option4"
                v-model="lc29_43_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q43_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q44ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">44. Hatten Sie brennende oder entzündete Augen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(44)"
                id="q44_option1"
                v-model="lc29_44_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q44_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(44)"
                id="q44_option2"
                v-model="lc29_44_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q44_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(44)"
                id="q44_option3"
                v-model="lc29_44_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q44_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(44)"
                id="q44_option4"
                v-model="lc29_44_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q44_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q45ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">45. Fühlten Sie sich schwindelig?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(45)"
                id="q45_option1"
                v-model="lc29_45_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q45_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(45)"
                id="q45_option2"
                v-model="lc29_45_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q45_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(45)"
                id="q45_option3"
                v-model="lc29_45_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q45_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(45)"
                id="q45_option4"
                v-model="lc29_45_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q45_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q46ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">46. Hatten Sie abblätternde Finger- oder Zehennägel?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(46)"
                id="q46_option1"
                v-model="lc29_46_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q46_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(46)"
                id="q46_option2"
                v-model="lc29_46_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q46_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(46)"
                id="q46_option3"
                v-model="lc29_46_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q46_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(46)"
                id="q46_option4"
                v-model="lc29_46_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q46_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q47ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">47. Hatten Sie Hautprobleme (z.B. Jucken, Trockenheit)?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(47)"
                id="q47_option1"
                v-model="lc29_47_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q47_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(47)"
                id="q47_option2"
                v-model="lc29_47_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q47_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(47)"
                id="q47_option3"
                v-model="lc29_47_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q47_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(47)"
                id="q47_option4"
                v-model="lc29_47_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q47_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q48ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">48. Hatten Sie Probleme beim Sprechen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(48)"
                id="q48_option1"
                v-model="lc29_48_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q48_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(48)"
                id="q48_option2"
                v-model="lc29_48_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q48_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(48)"
                id="q48_option3"
                v-model="lc29_48_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q48_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(48)"
                id="q48_option4"
                v-model="lc29_48_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q48_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q49ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">49. Hatten Sie Angst, dass Ihr Tumor fortschreitet?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(49)"
                id="q49_option1"
                v-model="lc29_49_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q49_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(49)"
                id="q49_option2"
                v-model="lc29_49_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q49_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(49)"
                id="q49_option3"
                v-model="lc29_49_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q49_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(49)"
                id="q49_option4"
                v-model="lc29_49_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q49_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q50ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              50. Hatten Sie dünnes oder mattes Haar als Folge Ihrer Krankheit
              oder Behandlung?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(50)"
                id="q50_option1"
                v-model="lc29_50_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q50_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(50)"
                id="q50_option2"
                v-model="lc29_50_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q50_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(50)"
                id="q50_option3"
                v-model="lc29_50_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q50_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(50)"
                id="q50_option4"
                v-model="lc29_50_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q50_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q51ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              51. Haben Sie sich Sorgen um Ihre zukünftige Gesundeit gemacht?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(51)"
                id="q51_option1"
                v-model="lc29_51_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q51_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(51)"
                id="q51_option2"
                v-model="lc29_51_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q51_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(51)"
                id="q51_option3"
                v-model="lc29_51_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q51_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(51)"
                id="q51_option4"
                v-model="lc29_51_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q51_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q52ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">52. Hatten Sie trockenen Husten?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(52)"
                id="q52_option1"
                v-model="lc29_52_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q52_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(52)"
                id="q52_option2"
                v-model="lc29_52_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q52_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(52)"
                id="q52_option3"
                v-model="lc29_52_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q52_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(52)"
                id="q52_option4"
                v-model="lc29_52_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q52_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q53ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              53. Hatten Sie erlebt, dass Ihre körperlichen Fähigkeiten
              abgenommen haben?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(53)"
                id="q53_option1"
                v-model="lc29_53_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q53_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(53)"
                id="q53_option2"
                v-model="lc29_53_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q53_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(53)"
                id="q53_option3"
                v-model="lc29_53_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q53_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(53)"
                id="q53_option4"
                v-model="lc29_53_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q53_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q54ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">54. War Gewichtsverlust für Sie ein Problem?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(54)"
                id="q54_option1"
                v-model="lc29_54_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q54_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(54)"
                id="q54_option2"
                v-model="lc29_54_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q54_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(54)"
                id="q54_option3"
                v-model="lc29_54_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q54_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(54)"
                id="q54_option4"
                v-model="lc29_54_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q54_option4">4</label>
            </td>
          </tr>
        </tbody>
        <thead >
       
            <th scope="col" colspan="4" class="align-middle">Beantworten Sie die folgenden Fragen nur, wenn Sie wegen Ihres
              Lungentumors <u>operiert</u> wurden:
            </th>
                <th scope="col" class="text-center" style="width: 100px">über-haupt nicht</th>
            <th scope="col" class="text-center" style="width: 100px">wenig</th>
            <th scope="col" class="text-center" style="width: 100px">ziemlich</th>
            <th scope="col" class="text-center" style="width: 100px">sehr</th>
          
        </thead>
        <tbody>
          <tr :class="VALIDATION['q55ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">55. Hatten Sie im Operationsbereich Schmerzen?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(55)"
                id="q55_option1"
                v-model="lc29_55_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q55_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(55)"
                id="q55_option2"
                v-model="lc29_55_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q55_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(55)"
                id="q55_option3"
                v-model="lc29_55_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q55_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(55)"
                id="q55_option4"
                v-model="lc29_55_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q55_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q56ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">56. War der Wundbereich übermäßig empfindlich?</td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(56)"
                id="q56_option1"
                v-model="lc29_56_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q56_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(56)"
                id="q56_option2"
                v-model="lc29_56_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q56_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(56)"
                id="q56_option3"
                v-model="lc29_56_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q56_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(56)"
                id="q56_option4"
                v-model="lc29_56_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q56_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q57ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              57. Waren Sie durch das Ausmaß der Operation in Ihrer
              Leistungsfähigkeit eingeschränkt?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(57)"
                id="q57_option1"
                v-model="lc29_57_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q57_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(57)"
                id="q57_option2"
                v-model="lc29_57_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q57_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(57)"
                id="q57_option3"
                v-model="lc29_57_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q57_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(57)"
                id="q57_option4"
                v-model="lc29_57_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q57_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q58ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              58. Hatten Sie Schwierigkeiten, Arm oder Schulter auf der
              operierten Seite Ihres Brustkorbs zu benutzen?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(58)"
                id="q58_option1"
                v-model="lc29_58_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q58_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(58)"
                id="q58_option2"
                v-model="lc29_58_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q58_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(58)"
                id="q58_option3"
                v-model="lc29_58_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q58_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(58)"
                id="q58_option4"
                v-model="lc29_58_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q58_option4">4</label>
            </td>
          </tr>
          <tr :class="VALIDATION['q59ok'] ? 'table-primary' : 'table-danger'">
            <td colspan="4">
              59. Haben die Narbenschmerzen Sie in Ihrem alltäglichen Leben
              beeinträchtigt?
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(59)"
                id="q59_option1"
                v-model="lc29_59_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q59_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(59)"
                id="q59_option2"
                v-model="lc29_59_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q59_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(59)"
                id="q59_option3"
                v-model="lc29_59_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q59_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(59)"
                id="q59_option4"
                v-model="lc29_59_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q59_option4">4</label>
            </td>
          </tr>
        </tbody>
         <thead >
            <th scope="col" colspan="4" class="align-middle">Hatten Sie während der letzten Woche weitere Symptome oder Probleme, die nicht im Fragebogen enthalten aber für Sie wichtig waren?</th>
                <th scope="col" class="text-center" style="width: 100px">über-haupt nicht</th>
            <th scope="col" class="text-center" style="width: 100px">wenig</th>
            <th scope="col" class="text-center" style="width: 100px">ziemlich</th>
            <th scope="col" class="text-center" style="width: 100px">sehr</th>
        </thead>
        <tbody>
          <tr>
            <td colspan="4">60. <input type="text" v-model="lc29_60_text_value" /></td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="1"
                :name="questionRadioName(60)"
                id="q60_option1"
                v-model="lc29_60_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q60_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="2"
                :name="questionRadioName(60)"
                id="q60_option2"
                v-model="lc29_60_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q60_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="3"
                :name="questionRadioName(60)"
                id="q60_option3"
                v-model="lc29_60_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q60_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="4"
                :name="questionRadioName(60)"
                id="q60_option4"
                v-model="lc29_60_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q60_option4">4</label>
            </td>
          </tr>
          <tr>
            <td colspan="4">61. <input type="text" v-model="lc29_61_text_value" /></td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="1"
                :name="questionRadioName(61)"
                id="q61_option1"
                v-model="lc29_61_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q61_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="2"
                :name="questionRadioName(61)"
                id="q61_option2"
                v-model="lc29_61_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q61_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="3"
                :name="questionRadioName(61)"
                id="q61_option3"
                v-model="lc29_61_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q61_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="4"
                :name="questionRadioName(61)"
                id="q61_option4"
                v-model="lc29_61_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q61_option4">4</label>
            </td>
          </tr>
          <tr>
            <td colspan="4">62. <input type="text" v-model="lc29_62_text_value" /></td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="1"
                :name="questionRadioName(62)"
                id="q62_option1"
                v-model="lc29_62_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q62_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="2"
                :name="questionRadioName(62)"
                id="q62_option2"
                v-model="lc29_62_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q62_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="3"
                :name="questionRadioName(62)"
                id="q62_option3"
                v-model="lc29_62_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q62_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="4"
                :name="questionRadioName(62)"
                id="q62_option4"
                v-model="lc29_62_value"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q62_option4">4</label>
            </td>
          </tr>
          <thead v-if="show_therapy_questions">
            <th scope="col" colspan="4" class="align-middle">63. Welche der folgenden Therapien haben Sie in den letzten 3 Monaten in Anspruch genommen?</th>
          </thead>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy1"/> Physiotherapie </td> 
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy2"/> Psychotherapie / psychologische Unterstützung </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy3"/> Schmerztherapie / Schmerzmittel </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy4"/> Sport </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy5"/> Ernährungsberatung</td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy6"/> Atemtherapie </td>
          </tr>
          <th v-if="show_therapy_questions">Beratungsangebote</th>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy7"/> Finanzielle Beratung </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy8"/> Rentenberatung </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy9"/> Rehaberatung </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy10"/> Erziehungsberatung </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy11"/> Paarberatung </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"><input type="checkbox" v-model="therapy12"/> Psychosoziale Krebsberatungsstelle (Bayerische Krebsgesellschaft e.V) </td>
          </tr>
          <tr v-if="show_therapy_questions">
            <td colspan="4"> Sonstiges <input type="text" v-model="therapy13_text"/></td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-if="MEASUREMENT.can_edit">
      Vielen Dank für das Ausfüllen des Fragebogens. Klicken Sie bitte auf
      Speichern, um die Befragung abzuschließen.
    </p>
    <div
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Alle Fragen beantwortet?"
    >
      <button v-if="MEASUREMENT.can_edit" @click="endQuestionaire()" type="button" class="btn btn-primary">
        Speichern
      </button>
    </div>
    <notification-modal
        ref="notificationModal"
            :show="notificationModalVisible"
            @modalClosed="notificationModalClosed"
            :notification="NOTIFICATION"
            :showSaveButton="is_2"
            @saveButtonClicked="saveButtonClicked"
            v-if="notificationModalVisible && NOTIFICATION != ''">

        </notification-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import router from "@/router";
import axios from "@/core/api";
import NotificationModal from '@/modules/admins/components/NotificationModal'

export default defineComponent({
  data() {
    return {
      notificationModalVisible: false,
      is_2: localStorage.getItem('user_type')  ? JSON.parse(localStorage.getItem('user_type')).enum == 2: false,
      show_therapy_questions: localStorage.getItem('show_therapy_questions') == "true"
    }
  },
  computed: {
    ...mapGetters({
      MEASUREMENT: "Measurements/MEASUREMENT",
      FK_USER: "Measurements/FK_USER",
      VALIDATION: "Measurements/VALIDATION",
      NOTIFICATION: 'Admin/NOTIFICATION'
    }),

    c30_1_value: {
      get() {
        return this.MEASUREMENT.values[0];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 0,
          value: value,
        });
      },
    },
    c30_2_value: {
      get() {
        return this.MEASUREMENT.values[1];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 1,
          value: value,
        });
      },
    },
    c30_3_value: {
      get() {
        return this.MEASUREMENT.values[2];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 2,
          value: value,
        });
      },
    },
    c30_4_value: {
      get() {
        return this.MEASUREMENT.values[3];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 3,
          value: value,
        });
      },
    },
    c30_5_value: {
      get() {
        return this.MEASUREMENT.values[4];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 4,
          value: value,
        });
      },
    },
    c30_6_value: {
      get() {
        return this.MEASUREMENT.values[5];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 5,
          value: value,
        });
      },
    },
    c30_7_value: {
      get() {
        return this.MEASUREMENT.values[6];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 6,
          value: value,
        });
      },
    },
    c30_8_value: {
      get() {
        return this.MEASUREMENT.values[7];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 7,
          value: value,
        });
      },
    },
    c30_9_value: {
      get() {
        return this.MEASUREMENT.values[8];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 8,
          value: value,
        });
      },
    },
    c30_10_value: {
      get() {
        return this.MEASUREMENT.values[9];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 9,
          value: value,
        });
      },
    },
    c30_11_value: {
      get() {
        return this.MEASUREMENT.values[10];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 10,
          value: value,
        });
      },
    },
    c30_12_value: {
      get() {
        return this.MEASUREMENT.values[11];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 11,
          value: value,
        });
      },
    },
    c30_13_value: {
      get() {
        return this.MEASUREMENT.values[12];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 12,
          value: value,
        });
      },
    },
    c30_14_value: {
      get() {
        return this.MEASUREMENT.values[13];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 13,
          value: value,
        });
      },
    },
    c30_15_value: {
      get() {
        return this.MEASUREMENT.values[14];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 14,
          value: value,
        });
      },
    },
    c30_16_value: {
      get() {
        return this.MEASUREMENT.values[15];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 15,
          value: value,
        });
      },
    },
    c30_17_value: {
      get() {
        return this.MEASUREMENT.values[16];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 16,
          value: value,
        });
      },
    },
    c30_18_value: {
      get() {
        return this.MEASUREMENT.values[17];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 17,
          value: value,
        });
      },
    },
    c30_19_value: {
      get() {
        return this.MEASUREMENT.values[18];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 18,
          value: value,
        });
      },
    },
    c30_20_value: {
      get() {
        return this.MEASUREMENT.values[19];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 19,
          value: value,
        });
      },
    },
    c30_21_value: {
      get() {
        return this.MEASUREMENT.values[20];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 20,
          value: value,
        });
      },
    },
    c30_22_value: {
      get() {
        return this.MEASUREMENT.values[21];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 21,
          value: value,
        });
      },
    },
    c30_23_value: {
      get() {
        return this.MEASUREMENT.values[22];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 22,
          value: value,
        });
      },
    },
    c30_24_value: {
      get() {
        return this.MEASUREMENT.values[23];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 23,
          value: value,
        });
      },
    },
    c30_25_value: {
      get() {
        return this.MEASUREMENT.values[24];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 24,
          value: value,
        });
      },
    },
    c30_26_value: {
      get() {
        return this.MEASUREMENT.values[25];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 25,
          value: value,
        });
      },
    },
    c30_27_value: {
      get() {
        return this.MEASUREMENT.values[26];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 26,
          value: value,
        });
      },
    },
    c30_28_value: {
      get() {
        return this.MEASUREMENT.values[27];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 27,
          value: value,
        });
      },
    },
    c30_29_value: {
      get() {
        return this.MEASUREMENT.values[28];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 28,
          value: value,
        });
      },
    },
    c30_30_value: {
      get() {
        return this.MEASUREMENT.values[29];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 29,
          value: value,
        });
      },
    },
    lc29_31_value: {
      get() {
        return this.MEASUREMENT.values[30];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 30,
          value: value,
        });
      },
    },
    lc29_32_value: {
      get() {
        return this.MEASUREMENT.values[31];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 31,
          value: value,
        });
      },
    },
    lc29_33_value: {
      get() {
        return this.MEASUREMENT.values[32];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 32,
          value: value,
        });
      },
    },
    lc29_34_value: {
      get() {
        return this.MEASUREMENT.values[33];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 33,
          value: value,
        });
      },
    },
    lc29_35_value: {
      get() {
        return this.MEASUREMENT.values[34];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 34,
          value: value,
        });
      },
    },
    lc29_36_value: {
      get() {
        return this.MEASUREMENT.values[35];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 35,
          value: value,
        });
      },
    },
    lc29_37_value: {
      get() {
        return this.MEASUREMENT.values[36];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 36,
          value: value,
        });
      },
    },
    lc29_38_value: {
      get() {
        return this.MEASUREMENT.values[37];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 37,
          value: value,
        });
      },
    },
    lc29_39_value: {
      get() {
        return this.MEASUREMENT.values[38];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 38,
          value: value,
        });
      },
    },
    lc29_40_value: {
      get() {
        return this.MEASUREMENT.values[39];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 39,
          value: value,
        });
      },
    },
    lc29_41_value: {
      get() {
        return this.MEASUREMENT.values[40];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 40,
          value: value,
        });
      },
    },
    lc29_42_value: {
      get() {
        return this.MEASUREMENT.values[41];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 41,
          value: value,
        });
      },
    },
    lc29_43_value: {
      get() {
        return this.MEASUREMENT.values[42];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 42,
          value: value,
        });
      },
    },
    lc29_44_value: {
      get() {
        return this.MEASUREMENT.values[43];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 43,
          value: value,
        });
      },
    },
    lc29_45_value: {
      get() {
        return this.MEASUREMENT.values[44];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 44,
          value: value,
        });
      },
    },
    lc29_46_value: {
      get() {
        return this.MEASUREMENT.values[45];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 45,
          value: value,
        });
      },
    },
    lc29_47_value: {
      get() {
        return this.MEASUREMENT.values[46];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 46,
          value: value,
        });
      },
    },
    lc29_48_value: {
      get() {
        return this.MEASUREMENT.values[47];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 47,
          value: value,
        });
      },
    },
    lc29_49_value: {
      get() {
        return this.MEASUREMENT.values[48];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 48,
          value: value,
        });
      },
    },
    lc29_50_value: {
      get() {
        return this.MEASUREMENT.values[49];
      },
      set(value) {
        this;
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 49,
          value: value,
        });
      },
    },
    lc29_51_value: {
      get() {
        return this.MEASUREMENT.values[50];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 50,
          value: value,
        });
      },
    },
    lc29_52_value: {
      get() {
        return this.MEASUREMENT.values[51];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 51,
          value: value,
        });
      },
    },
    lc29_53_value: {
      get() {
        return this.MEASUREMENT.values[52];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 52,
          value: value,
        });
      },
    },
    lc29_54_value: {
      get() {
        return this.MEASUREMENT.values[53];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 53,
          value: value,
        });
      },
    },
    lc29_55_value: {
      get() {
        return this.MEASUREMENT.values[54];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 54,
          value: value,
        });
      },
    },
    lc29_56_value: {
      get() {
        return this.MEASUREMENT.values[55];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 55,
          value: value,
        });
      },
    },
    lc29_57_value: {
      get() {
        return this.MEASUREMENT.values[56];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 56,
          value: value,
        });
      },
    },
    lc29_58_value: {
      get() {
        return this.MEASUREMENT.values[57];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 57,
          value: value,
        });
      },
    },
    lc29_59_value: {
      get() {
        return this.MEASUREMENT.values[58];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 58,
          value: value,
        });
      },
    },
    lc29_60_value: {
      get() {
        return this.MEASUREMENT.values[59];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 59,
          value: value,
        });
      },
    },
    lc29_60_text_value: {
      get() {
        return this.MEASUREMENT.lc29_60_text;
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_LC29_60_TEXT_VALUE", {
          value: value,
        });
      },
    },
    lc29_61_value: {
      get() {
        return this.MEASUREMENT.values[60];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 60,
          value: value,
        });
      },
    },
    lc29_61_text_value: {
      get() {
        return this.MEASUREMENT.lc29_61_text;
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_LC29_61_TEXT_VALUE", {
          value: value,
        });
      },
    },
    lc29_62_value: {
      get() {
        return this.MEASUREMENT.values[61];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 61,
          value: value,
        });
      },
    },
    lc29_62_text_value: {
      get() {
        return this.MEASUREMENT.lc29_62_text;
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_LC29_62_TEXT_VALUE", {
          value: value,
        });
      },
    },
    therapy1: {
      get() {
        return this.MEASUREMENT.therapy_1 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_1", {
          value: value,
        });
      },
    },
    therapy2: {
      get() {
        return this.MEASUREMENT.therapy_2 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_2", {
          value: value,
        });
      },
    },
    therapy3: {
      get() {
        return this.MEASUREMENT.therapy_3 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_3", {
          value: value,
        });
      },
    },
    therapy4: {
      get() {
        return this.MEASUREMENT.therapy_4 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_4", {
          value: value,
        });
      },
    },
    therapy5: {
      get() {
        return this.MEASUREMENT.therapy_5 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_5", {
          value: value,
        });
      },
    },
    therapy6: {
      get() {
        return this.MEASUREMENT.therapy_6 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_6", {
          value: value,
        });
      },
    },
    therapy7: {
      get() {
        return this.MEASUREMENT.therapy_7 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_7", {
          value: value,
        });
      },
    },
    therapy8: {
      get() {
        return this.MEASUREMENT.therapy_8 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_8", {
          value: value,
        });
      },
    },
    therapy9: {
      get() {
        return this.MEASUREMENT.therapy_9 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_9", {
          value: value,
        });
      },
    },
    therapy10: {
      get() {
        return this.MEASUREMENT.therapy_10 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_10", {
          value: value,
        });
      },
    },
    therapy11: {
      get() {
        return this.MEASUREMENT.therapy_11 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_11", {
          value: value,
        });
      },
    },
    therapy12: {
      get() {
        return this.MEASUREMENT.therapy_12 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_12", {
          value: value,
        });
      },
    },
    therapy13_text: {
      get() {
        return this.MEASUREMENT.therapy_13_text;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_13_TEXT", {
          value: value,
        });
      },
    },

  },
  methods: {
    save() {
      this.$store.dispatch("Measurements/SAVE", {
        measurement: this.MEASUREMENT,
      });
    },
    async endQuestionaire() {
      this.$store.commit("Measurements/VALIDATE_MEASUREMENT", {measurement: this.MEASUREMENT, show_therapy_questions: this.show_therapy_questions});
      if (Object.values(this.VALIDATION).every((element) => element)) {
        this.save();
        var url = "/measurements/redirect";
      const response = await axios.get(url);
      if (response.status == 200) {
        router.push(response.data.url);
      }
      } else {
        this.$store.commit("Admin/SET_NOTIFICATION", "Es gibt noch unbeantwortete Fragen.")
        this.showNotificationModal();
        return;
      }
    },
    async saveButtonClicked() { //skip validation
      this.save();
        var url = "/measurements/redirect";
        const response = await axios.get(url);
      if (response.status == 200) {
        router.push(response.data.url);
      }
    },

    questionRadioName(id) {
      return "radio-" + id;
    },
    notificationModalClosed(){
            this.notificationModalVisible = false
            this.$store.commit("Admin/SET_NOTIFICATION", "")
        },
        showNotificationModal(){
            this.notificationModalVisible = true
        }
  },
  components : {
    NotificationModal
  }
});
</script>
<style scoped>
.right {
  text-align: right;
}
.margins {
  margin-top: 100px;
}
.text-center{
  min-width: 70px;
  max-width: 70px;
}
</style>