<template>
    <div class="container p-5">
      <error-list :errors="ERRORS" />
      <error-list :errors="ERRORS2" variant="warning" title="Hinweis" />
  
      <div class="row align-items-center">
        <div class="col-sm-10 col-md-10 col-xs-8 mx-auto">
          <div class="card text-center">
            <h5 class="card-header">
              LePaLuMo<br />LQ-Monitoring von Patienten mit Lungenkarzinom
            </h5>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-md-12 col-xs-12 pt-2">
                  <img
                    alt="TUZBT"
                    src="@/assets/logo-university-of-bayreuth.png"
                    class="img-fluid p-2"
                    style="width: 170px"
                  />
                </div>
                <div class="col-lg-4 col-md-12 col-xs-12">
                  <img
                    alt="TUZR"
                    src="@/assets/logo_tuz.png"
                    class="img-fluid p-2"
                    style="width: 170px"
                  />
                </div>
                <!--div class="col-lg-3 col-md-12 col-xs-12">
                  <img
                    alt="ZKS"
                    src="@/assets/logo_zks.png"
                    class="img-fluid p-2"
                    style="width: 140px"
                  />
                </div-->
                <div class="col-lg-4 col-md-12 col-xs-12">
                  <img
                    alt="LGL"
                    src="@/assets/logo_lepalumo.png"
                    class="img-fluid p-2"
                    style="width: 130px"
                  />
                </div>
              </div>
            </div>
           <div class="card bigcard">
            Sehr geehrte Interessentin, sehr geehrter Interessent,
über folgenden Button können Sie einen Testbenutzer anlegen.
Mit diesem können Sie die Anwendung testweise nutzen.
Bitte beachten Sie, dass der Zugang nur eine Stunde gültig ist.
<button class="btn btn-primary" @click="create_testuser()">Testbenutzer anlegen</button>
<hr>

<p>Username: {{ USER }}</p>
<p>Password: {{ PASSWORD }}</p>
           </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ErrorList from "@/core/components/ErrorList";
  import { mapGetters, useStore } from "vuex";
  
  export default {
    name: "Testuser",
    components: {
      ErrorList,
    },
    setup() {
      const store = useStore();
  
        const create_testuser = () => {
            store.dispatch("User/CREATE_TESTUSER")
        }



      return {
        create_testuser
      };
    },
    computed: {
      ...mapGetters({ ERRORS: "User/ERRORS", ERRORS2: "User/ERRORS2", USER: "User/TESTUSER_USER", PASSWORD: "User/TESTUSER_PASSWORD", }),

      errors: {
        get() {
          return this.ERRORS;
        },
        set(value) {
          this.$store.commit("USER/SET_ERRORS", value);
        },
      },
  
      errors2: {
        get() {
          return this.ERRORS2;
        },
        set(value) {
          this.$store.commit("USER/SET_ERRORS2", value);
        },
      },
    },
  };
  </script>
