<template>
  <div class="card col bigcard-mobile">
    <div class="row">
      <div class="col">
        <button
          @click="this.previous()"
          class="btn btn-primary"
          :disabled="page == 0"
        >
          Zurück
        </button>
      </div>
      <div class="col">
        <button
          @click="this.nextQuestion()"
          class="btn btn-primary"
          :disabled="(page == 64 && !this.show_therapy_questions) || page == 67"
        >
          Nächste Frage
        </button>
      </div>
    </div>
    <div class="card-body">
      <h5 v-if="page == 0" class="card-title">Lebensqualitätsfragebogen (EORTC QLQ-C30)</h5>
      <p v-if="page == 0">
        Wir sind an einigen Angaben interessiert, die Sie und Ihre Gesundheit
        betreffen. Bitte beantworten Sie die folgenden Fragen selbst, indem Sie
        die Zahl ankreuzen, die am besten auf Sie zutrifft. Es gibt keine
        "richtigen" oder "falschen" Antworten. Ihre Angaben werden streng
        vertraulich behandelt.
      </p>
      <div v-if="page >= 6 && page <= 28"><b>Während der letzten Woche:</b></div>
      <div v-if="page >= 32 && page <= 55"><b>Während der letzten Woche:</b></div>
      <div v-if="page >= 64 && page <= 66"><b>          <thead v-if="show_therapy_questions">
            <th scope="col" colspan="4" class="align-middle">Welche der folgenden Therapien haben Sie in den letzten 3 Monaten in Anspruch genommen?</th>
          </thead></b></div>
      <div v-if="page >= 56 && page <= 60">
        Beantworten Sie die folgenden Fragen nur, wenn Sie wegen Ihres
        Lungentumors <u>operiert</u> wurden
      </div>
      <div v-if="page >= 61 && page <= 63">
        Hatten Sie während der letzten Woche weitere Symptome oder Probleme, die nicht im Fragebogen enthalten aber für Sie wichtig waren?
      </div>

      <p v-if="page >= 29 && page <= 30">
        <b
          >Bitte kreuzen Sie bei den folgenden Fragen die Zahl zwischen 1 und 7
          an, die am besten auf Sie zutrifft</b
        >
      </p>
      <p v-if="page == 31">
        Patienten berichten manchmal die folgenden Symptome oder Probleme. Bitte
        geben Sie an, in welchem Ausmaß Sie diese Symptome oder Probleme
        <u>während der letzten Woche</u> erlebt haben. Markieren Sie bitte die
        Zahl, die am besten auf Sie zutrifft.
      </p>

      <div v-if="page == 1">
        1. Bereitet es Ihnen Schwierigkeiten, sich körperlich anzustrengen? (z.B.
        eine schwere Einkaufstasche oder einen Koffer zu tragen)
      </div>
      <div v-if="page == 2">
        2. Bereitet es Ihnen Schwierigkeiten, einen <u>längeren</u> Spaziergang zu
        machen?
      </div>
      <div v-if="page == 3">
        3. Bereitet es Ihnen Schwierigkeiten, eine <u>kurze</u> Strecke außer Haus zu
        gehen?
      </div>
      <div v-if="page == 4">
        4. Müssen Sie tagsüber im Bett liegen oder in einem Sessel sitzen?
      </div>
      <div v-if="page == 5">
        5. Brauchen Sie Hilfe beim Essen, Anziehen, Waschen oder Benutzen der
        Toilette?
      </div>
      <div v-if="page == 6">
        6. Waren Sie bei Ihrer Arbeit oder bei anderen tagtäglichen
        Beschäftigungen eingeschränkt?
      </div>
      <div v-if="page == 7">
        7. Waren Sie bei Ihren Hobbys oder anderen Freizeitbeschäftigungen
        eingeschränkt?
      </div>
      <div v-if="page == 8">8. Waren Sie kurzatmig?</div>
      <div v-if="page == 9">9. Hatten Sie Schmerzen?</div>
      <div v-if="page == 10">10. Mussten Sie sich ausruhen?</div>
      <div v-if="page == 11">11. Hatten Sie Schlafstörungen?</div>
      <div v-if="page == 12">12. Fühlten Sie sich schwach?</div>
      <div v-if="page == 13">13. Hatten Sie Appetitmangel?</div>
      <div v-if="page == 14">14. War Ihnen übel?</div>
      <div v-if="page == 15">15. Haben Sie erbrochen?</div>
      <div v-if="page == 16">16. Hatten Sie Verstopfung?</div>
      <div v-if="page == 17">17. Hatten Sie Durchfall?</div>
      <div v-if="page == 18">18. Waren Sie müde?</div>
      <div v-if="page == 19">
        19. Fühlten Sie sich durch Schmerzen in Ihrem alltäglichen Leben
        beeinträchtigt?
      </div>
      <div v-if="page == 20">
        20. Hatten Sie Schwierigkeiten, sich auf etwas zu konzentrieren, z.B.
        auf das Zeitunglesen oder das Fernsehen?
      </div>
      <div v-if="page == 21">21. Fühlten Sie sich angespannt?</div>
      <div v-if="page == 22">22. Haben Sie sich Sorgen gemacht?</div>
      <div v-if="page == 23">23. Waren Sie reizbar?</div>
      <div v-if="page == 24">24. Fühlten Sie sich niedergeschlagen?</div>
      <div v-if="page == 25">
        25. Hatten Sie Schwierigkeiten, sich an Dinge zu erinnern?
      </div>
      <div v-if="page == 26">
        26. Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung Ihr
        <u>Familienleben</u> beeinträchtigt?
      </div>
      <div v-if="page == 27">
        27. Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung Ihr
        Zusammensein oder Ihre gemeinsamen Unternehmungen <u>mit anderen Menschen</u>
        beeinträchtigt?
      </div>
      <div v-if="page == 28">
        28. Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung für
        Sie finanzielle Schwierigkeiten mit sich gebracht?
      </div>
      <div v-if="page == 29">
        29. Wie würden Sie insgesamt Ihren <u>Gesundheitszustand</u> während der
        letzten Woche einschätzen?
      </div>
      <div v-if="page == 30">
        30. Wie würden Sie insgesamt Ihre <u>Lebensqualität</u> während der
        letzten Woche einschätzen?
      </div>
      <div v-if="page == 32">31. Haben Sie gehustet?</div>
      <div v-if="page == 33">32. Haben Sie Blut gehustet?</div>
      <div v-if="page == 34">
        33. Waren Sie unter Ruhebedingungen kurzatmig?
      </div>
      <div v-if="page == 35">34. Waren Sie beim Gehen kurzatmig?</div>
      <div v-if="page == 36">35. Waren Sie beim Treppensteigen kurzatmig?</div>
      <div v-if="page == 37">36. War Ihr Mund oder Ihre Zunge wund?</div>
      <div v-if="page == 38">37. Hatten Sie Probleme beim Schlucken?</div>
      <div v-if="page == 39">
        38. Hatten Sie Kribbeln in den Händen oder Füßen?
      </div>
      <div v-if="page == 40">39. Hatten Sie Haarausfall?</div>
      <div v-if="page == 41">40. Hatten Sie Schmerzen im Brustkorb?</div>
      <div v-if="page == 42">
        41. Hatten Sie Schmerzen in Ihrem Arm oder in Ihrer Schulter?
      </div>
      <div v-if="page == 43">
        42. Hatten Sie an anderen Stellen des Körpers Schmerzen?
      </div>
      <div v-if="page == 44">43. Hatten Sie allergische Reaktionen?</div>
      <div v-if="page == 45">
        44. Hatten Sie brennende oder entzündete Augen?
      </div>
      <div v-if="page == 46">45. Fühlten Sie sich schwindelig?</div>
      <div v-if="page == 47">
        46. Hatten Sie abblätternde Finger- oder Zehennägel
      </div>
      <div v-if="page == 48">
        47. Hatten Sie Hautprobleme (z.B. Jucken, Trockenheit?)
      </div>
      <div v-if="page == 49">48. Hatten Sie Probleme beim Sprechen?</div>
      <div v-if="page == 50">
        49. Hatten Sie Angst, dass Ihr Tumor fortschreitet
      </div>
      <div v-if="page == 51">
        50. Hatten Sie dünnes oder mattes Haar als Folge Ihrer Krankheit oder
        Behandlung?
      </div>
      <div v-if="page == 52">
        51. Haben Sie sich Sorgen um Ihre zukünftige Gesundheit gemacht?
      </div>
      <div v-if="page == 53">52. Hatten Sie trockenen Husten?</div>
      <div v-if="page == 54">
        53. Haben Sie erlebt, dass Ihre körperlichen Fähigkeiten abgenommen
        haben?
      </div>
      <div v-if="page == 55">54. War Gewichtsverlust für Sie ein Problem?</div>
      <div v-if="page == 56">
        55. Hatten Sie im Operationsbereich Schmerzen?
      </div>
      <div v-if="page == 57">
        56. War der Wundbereich übermäßig empfindlich?
      </div>
      <div v-if="page == 58">
        57. Waren Sie durch das Ausmaß der Operation in Ihrer Leistungsfähigkeit
        eingeschränkt?
      </div>
      <div v-if="page == 59">
        58. Hatten Sie Schwierigkeiten, Arm oder Schulter auf der operierten
        Seite Ihres Brustkorbs zu benutzen?
      </div>
      <div v-if="page == 60">
        59. Haben die Narbenschmerzen Sie in Ihrem alltäglichen Leben
        beeinträchtigt?
      </div>
      <div v-if="page == 61">
        60. <input type="text" v-model="lc29_60_text_value" />
      </div>
      <div v-if="page == 62">
        61. <input type="text" v-model="lc29_61_text_value" />
      </div>
      <div v-if="page == 63">
        62. <input type="text" v-model="lc29_62_text_value" />
      </div>
      <div v-if="page == 64 && show_therapy_questions">
        63.   <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy1" /> Physiotherapie </td> 
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy2"/> Psychotherapie / psychologische Unterstützung </td>
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy3"/> Schmerztherapie / Schmerzmittel </td>
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy4"/> Sport </td>
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy5"/> Ernährungsberatung </td>
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy6"/> Atemtherapie </td>
          </tr>
      </div>
      <div v-if="page == 65 && show_therapy_questions">
        <th>Beratungsangebote</th>
        64.   <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy7"/> Finanzielle Beratung </td>
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy8"/> Rentenberatung </td>
          </tr>
          <tr >
            <td colspan="4"><input type="checkbox" v-model="therapy9"/> Rehaberatung </td>
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy10"/> Erziehungsberatung </td>
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy11"/> Paarberatung </td>
          </tr>
          <tr>
            <td colspan="4"><input type="checkbox" v-model="therapy12"/> Psychosoziale Krebsberatungsstelle (Bayerische Krebsgesellschaft e.V) </td>
          </tr>
      </div>
      <div v-if="page == 66 && show_therapy_questions">
        65.   <tr>
            <td colspan="4"> Sonstiges <input type="text" v-model="therapy13_text"/></td>
          </tr>
      </div>

      <table
        class="table table-striped"
        v-if="(page >= 1 && page <= 28) || (page >= 32 && page <= 63)"
      >
        <thead v-if="page >= 1 && page <= 28">
          <tr>
            <th scope="col" class="text-center" style="width: 100px">Über-haupt nicht</th>
            <th scope="col" class="text-center" style="width: 100px">Wenig</th>
            <th scope="col" class="text-center" style="width: 100px">Ziemlich</th>
            <th scope="col" class="text-center" style="width: 100px">Sehr</th>
          </tr>
        </thead>
        <thead v-if="page >= 32 && page <= 63">
          <tr>
            <th scope="col" class="text-center" style="width: 100px">Über-haupt nicht</th>
            <th scope="col" class="text-center" style="width: 100px">Wenig</th>
            <th scope="col" class="text-center" style="width: 100px">Ziemlich</th>
            <th scope="col" class="text-center" style="width: 100px">Sehr</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="page == 1">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(1)"
                id="q1_option1"
                v-model="c30_1_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q1_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(1)"
                id="q1_option2"
                v-model="c30_1_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q1_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(1)"
                id="q1_option3"
                v-model="c30_1_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q1_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(1)"
                id="q1_option4"
                v-model="c30_1_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q1_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 2">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(2)"
                id="q2_option1"
                v-model="c30_2_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q2_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(2)"
                id="q2_option2"
                v-model="c30_2_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q2_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(2)"
                id="q2_option3"
                v-model="c30_2_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q2_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(2)"
                id="q2_option4"
                v-model="c30_2_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q2_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 3">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(3)"
                id="q3_option1"
                v-model="c30_3_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q3_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(3)"
                id="q3_option2"
                v-model="c30_3_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q3_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(3)"
                id="q3_option3"
                v-model="c30_3_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q3_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(3)"
                id="q3_option4"
                v-model="c30_3_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q3_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 4">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(4)"
                id="q4_option1"
                v-model="c30_4_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q4_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(4)"
                id="q4_option2"
                v-model="c30_4_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q4_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(4)"
                id="q4_option3"
                v-model="c30_4_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q4_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(4)"
                id="q4_option4"
                v-model="c30_4_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q4_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 5">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(5)"
                id="q5_option1"
                v-model="c30_5_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q5_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(5)"
                id="q5_option2"
                v-model="c30_5_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q5_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(5)"
                id="q5_option3"
                v-model="c30_5_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q5_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(5)"
                id="q5_option4"
                v-model="c30_5_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q5_option4">4</label>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-if="page == 6">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(6)"
                id="q6_option1"
                v-model="c30_6_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q6_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(6)"
                id="q6_option2"
                v-model="c30_6_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q6_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(6)"
                id="q6_option3"
                v-model="c30_6_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q6_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(6)"
                id="q6_option4"
                v-model="c30_6_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q6_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 7">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(7)"
                id="q7_option1"
                v-model="c30_7_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q7_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(7)"
                id="q7_option2"
                v-model="c30_7_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q7_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(7)"
                id="q7_option3"
                v-model="c30_7_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q7_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(7)"
                id="q7_option4"
                v-model="c30_7_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q7_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 8">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(8)"
                id="q8_option1"
                v-model="c30_8_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q8_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(8)"
                id="q8_option2"
                v-model="c30_8_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q8_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(8)"
                id="q8_option3"
                v-model="c30_8_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q8_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(8)"
                id="q8_option4"
                v-model="c30_8_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q8_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 9">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(9)"
                id="q9_option1"
                v-model="c30_9_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q9_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(9)"
                id="q9_option2"
                v-model="c30_9_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q9_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(9)"
                id="q9_option3"
                v-model="c30_9_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q9_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(9)"
                id="q9_option4"
                v-model="c30_9_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q9_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 10">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(10)"
                id="q10_option1"
                v-model="c30_10_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q10_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(10)"
                id="q10_option2"
                v-model="c30_10_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q10_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(10)"
                id="q10_option3"
                v-model="c30_10_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q10_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(10)"
                id="q10_option4"
                v-model="c30_10_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q10_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 11">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(11)"
                id="q11_option1"
                v-model="c30_11_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q11_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(11)"
                id="q11_option2"
                v-model="c30_11_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q11_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(11)"
                id="q11_option3"
                v-model="c30_11_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q11_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(11)"
                id="q11_option4"
                v-model="c30_11_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q11_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 12">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(12)"
                id="q12_option1"
                v-model="c30_12_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q12_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(12)"
                id="q12_option2"
                v-model="c30_12_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q12_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(12)"
                id="q12_option3"
                v-model="c30_12_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q12_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(12)"
                id="q12_option4"
                v-model="c30_12_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q12_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 13">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(13)"
                id="q13_option1"
                v-model="c30_13_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q13_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(13)"
                id="q13_option2"
                v-model="c30_13_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q13_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(13)"
                id="q13_option3"
                v-model="c30_13_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q13_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(13)"
                id="q13_option4"
                v-model="c30_13_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q13_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 14">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(14)"
                id="q14_option1"
                v-model="c30_14_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q14_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(14)"
                id="q14_option2"
                v-model="c30_14_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q14_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(14)"
                id="q14_option3"
                v-model="c30_14_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q14_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(14)"
                id="q14_option4"
                v-model="c30_14_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q14_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 15">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(15)"
                id="q15_option1"
                v-model="c30_15_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q15_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(15)"
                id="q15_option2"
                v-model="c30_15_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q15_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(15)"
                id="q15_option3"
                v-model="c30_15_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q15_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(15)"
                id="q15_option4"
                v-model="c30_15_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q15_option4">4</label>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-if="page == 16">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(16)"
                id="q16_option1"
                v-model="c30_16_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q16_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(16)"
                id="q16_option2"
                v-model="c30_16_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q16_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(16)"
                id="q16_option3"
                v-model="c30_16_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q16_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(16)"
                id="q16_option4"
                v-model="c30_16_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q16_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 17">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(17)"
                id="q17_option1"
                v-model="c30_17_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q17_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(17)"
                id="q17_option2"
                v-model="c30_17_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q17_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(17)"
                id="q17_option3"
                v-model="c30_17_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q17_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(17)"
                id="q17_option4"
                v-model="c30_17_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q17_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 18">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(18)"
                id="q18_option1"
                v-model="c30_18_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q18_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(18)"
                id="q18_option2"
                v-model="c30_18_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q18_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(18)"
                id="q18_option3"
                v-model="c30_18_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q18_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(18)"
                id="q18_option4"
                v-model="c30_18_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q18_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 19">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(19)"
                id="q19_option1"
                v-model="c30_19_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q19_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(19)"
                id="q19_option2"
                v-model="c30_19_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q19_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(19)"
                id="q19_option3"
                v-model="c30_19_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q19_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(19)"
                id="q19_option4"
                v-model="c30_19_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q19_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 20">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(20)"
                id="q20_option1"
                v-model="c30_20_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q20_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(20)"
                id="q20_option2"
                v-model="c30_20_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q20_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(20)"
                id="q20_option3"
                v-model="c30_20_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q20_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(20)"
                id="q20_option4"
                v-model="c30_20_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q20_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 21">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(21)"
                id="q21_option1"
                v-model="c30_21_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q21_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(21)"
                id="q21_option2"
                v-model="c30_21_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q21_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(21)"
                id="q21_option3"
                v-model="c30_21_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q21_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(21)"
                id="q21_option4"
                v-model="c30_21_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q21_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 22">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(22)"
                id="q22_option1"
                v-model="c30_22_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q22_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(22)"
                id="q22_option2"
                v-model="c30_22_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q22_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(22)"
                id="q22_option3"
                v-model="c30_22_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q22_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(22)"
                id="q22_option4"
                v-model="c30_22_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q22_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 23">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(23)"
                id="q23_option1"
                v-model="c30_23_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q23_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(23)"
                id="q23_option2"
                v-model="c30_23_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q23_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(23)"
                id="q23_option3"
                v-model="c30_23_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q23_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(23)"
                id="q23_option4"
                v-model="c30_23_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q23_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 24">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(24)"
                id="q24_option1"
                v-model="c30_24_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q24_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(24)"
                id="q24_option2"
                v-model="c30_24_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q24_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(24)"
                id="q24_option3"
                v-model="c30_24_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q24_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(24)"
                id="q24_option4"
                v-model="c30_24_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q24_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 25">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(25)"
                id="q25_option1"
                v-model="c30_25_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q25_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(25)"
                id="q25_option2"
                v-model="c30_25_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q25_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(25)"
                id="q25_option3"
                v-model="c30_25_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q25_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(25)"
                id="q25_option4"
                v-model="c30_25_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q25_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 26">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(26)"
                id="q26_option1"
                v-model="c30_26_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q26_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(26)"
                id="q26_option2"
                v-model="c30_26_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q26_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(26)"
                id="q26_option3"
                v-model="c30_26_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q26_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(26)"
                id="q26_option4"
                v-model="c30_26_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q26_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 27">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(27)"
                id="q27_option1"
                v-model="c30_27_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q27_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(27)"
                id="q27_option2"
                v-model="c30_27_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q27_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(27)"
                id="q27_option3"
                v-model="c30_27_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q27_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(27)"
                id="q27_option4"
                v-model="c30_27_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q27_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 28">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(28)"
                id="q28_option1"
                v-model="c30_28_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q28_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(28)"
                id="q28_option2"
                v-model="c30_28_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q28_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(28)"
                id="q28_option3"
                v-model="c30_28_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q28_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(28)"
                id="q28_option4"
                v-model="c30_28_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q28_option4">4</label>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tr v-if="page == 29">
            <br>
                <b>1 = sehr schlecht, 7 = ausgezeichnet</b><br>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="1"
                    :name="questionRadioName(29)"
                    id="q29_option1"
                    v-model="c30_29_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option1">1</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="2"
                    :name="questionRadioName(29)"
                    id="q29_option2"
                    v-model="c30_29_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option2">2</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="3"
                    :name="questionRadioName(29)"
                    id="q29_option3"
                    v-model="c30_29_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                   <label class="btn btn-outline-primary btn-lg" for="q29_option3">3</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="4"
                    :name="questionRadioName(29)"
                    id="q29_option4"
                    v-model="c30_29_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option4">4</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="5"
                    :name="questionRadioName(29)"
                    id="q29_option5"
                    v-model="c30_29_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                   <label class="btn btn-outline-primary btn-lg" for="q29_option5">5</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="6"
                    :name="questionRadioName(29)"
                    id="q29_option6"
                    v-model="c30_29_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q29_option6">6</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="7"
                    :name="questionRadioName(29)"
                    id="q29_option7"
                    v-model="c30_29_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                   <label class="btn btn-outline-primary btn-lg" for="q29_option7">7</label>
                </div> 
        </tr>
        <tr v-if="page == 30">
              <br>
                <b>1 = sehr schlecht, 7 = ausgezeichnet</b><br>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="1"
                    :name="questionRadioName(30)"
                    id="q30_option1"
                    v-model="c30_30_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option1">1</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="2"
                    :name="questionRadioName(30)"
                    id="q30_option2"
                    v-model="c30_30_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option2">2</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="3"
                    :name="questionRadioName(30)"
                    id="q30_option3"
                    v-model="c30_30_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option3">3</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="4"
                    :name="questionRadioName(30)"
                    id="q30_option4"
                    v-model="c30_30_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option4">4</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="5"
                    :name="questionRadioName(30)"
                    id="q30_option5"
                    v-model="c30_30_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option5">5</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="6"
                    :name="questionRadioName(30)"
                    id="q30_option6"
                    v-model="c30_30_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option6">6</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="btn-check"
                    value="7"
                    :name="questionRadioName(30)"
                    id="q30_option7"
                    v-model="c30_30_value"
                    @change="this.next()"
                     :disabled="!MEASUREMENT.can_edit"
                  />
                  <label class="btn btn-outline-primary btn-lg" for="q30_option7">7</label>
                </div>
        </tr>
      </table>
      <h5 v-if="page == 31" class="card-title">Lebensqualitätsfragebogen (EORTC QLQ-LC29)</h5>
      <table class="table table-striped">
        <tbody>
          <tr v-if="page == 32">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(31)"
                id="q31_option1"
                v-model="lc29_31_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q31_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(31)"
                id="q31_option2"
                v-model="lc29_31_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q31_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(31)"
                id="q31_option3"
                v-model="lc29_31_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q31_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(31)"
                 id="q31_option4"
                v-model="lc29_31_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q31_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 33">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(32)"
                id="q32_option1"
                v-model="lc29_32_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q32_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(32)"
                id="q32_option2"
                v-model="lc29_32_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q32_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(32)"
                id="q32_option3"
                v-model="lc29_32_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q32_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(32)"
                id="q32_option4"
                v-model="lc29_32_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q32_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 34">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(33)"
                id="q33_option1"
                v-model="lc29_33_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q33_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(33)"
                id="q33_option2"
                v-model="lc29_33_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q33_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(33)"
                id="q33_option3"
                v-model="lc29_33_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q33_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(33)"
                id="q33_option4"
                v-model="lc29_33_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q33_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 35">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(34)"
                id="q34_option1"
                v-model="lc29_34_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q34_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(34)"
                id="q34_option2"
                v-model="lc29_34_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q34_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(34)"
                id="q34_option3"
                v-model="lc29_34_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q34_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(34)"
                id="q34_option4"
                v-model="lc29_34_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q34_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 36">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(35)"
                id="q35_option1"
                v-model="lc29_35_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q35_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(35)"
                id="q35_option2"
                v-model="lc29_35_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q35_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(35)"
                id="q35_option3"
                v-model="lc29_35_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q35_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(35)"
                id="q35_option4"
                v-model="lc29_35_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q35_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 37">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(36)"
                id="q36_option1"
                v-model="lc29_36_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q36_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(36)"
                id="q36_option2"
                v-model="lc29_36_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q36_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(36)"
                id="q36_option3"
                v-model="lc29_36_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q36_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(36)"
                id="q36_option4"
                v-model="lc29_36_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q36_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 38">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(37)"
                id="q37_option1"
                v-model="lc29_37_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q37_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(37)"
                id="q37_option2"
                v-model="lc29_37_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q37_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(37)"
                id="q37_option3"
                v-model="lc29_37_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q37_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(37)"
                id="q37_option4"
                v-model="lc29_37_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q37_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 39">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(38)"
                id="q38_option1"
                v-model="lc29_38_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q38_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(38)"
                id="q38_option2"
                v-model="lc29_38_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q38_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(38)"
                id="q38_option3"
                v-model="lc29_38_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q38_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(38)"
                id="q38_option4"
                v-model="lc29_38_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q38_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 40">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(39)"
                id="q39_option1"
                v-model="lc29_39_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q39_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(39)"
                id="q39_option2"
                v-model="lc29_39_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q39_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(39)"
                id="q39_option3"
                v-model="lc29_39_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q39_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(39)"
                id="q39_option4"
                v-model="lc29_39_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q39_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 41">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(40)"
                id="q40_option1"
                v-model="lc29_40_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q40_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(40)"
                id="q40_option2"
                v-model="lc29_40_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q40_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(40)"
                id="q40_option3"
                v-model="lc29_40_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q40_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(40)"
                id="q40_option4"
                v-model="lc29_40_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q40_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 42">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(41)"
                id="q41_option1"
                v-model="lc29_41_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q41_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(41)"
                id="q41_option2"
                v-model="lc29_41_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q41_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(41)"
                id="q41_option3"
                v-model="lc29_41_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q41_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(41)"
                id="q41_option4"
                v-model="lc29_41_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q41_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 43">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(42)"
                id="q42_option1"
                v-model="lc29_42_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q42_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(42)"
                id="q42_option2"
                v-model="lc29_42_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q42_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(42)"
                id="q42_option3"
                v-model="lc29_42_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q42_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(42)"
                id="q42_option4"
                v-model="lc29_42_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q42_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 44">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(43)"
                id="q43_option1"
                v-model="lc29_43_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q43_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(43)"
                id="q43_option2"
                v-model="lc29_43_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q43_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(43)"
                id="q43_option3"
                v-model="lc29_43_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q43_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(43)"
                id="q43_option4"
                v-model="lc29_43_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q43_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 45">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(44)"
                id="q44_option1"
                v-model="lc29_44_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q44_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(44)"
                id="q44_option2"
                v-model="lc29_44_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q44_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(44)"
                id="q44_option3"
                v-model="lc29_44_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q44_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(44)"
                id="q44_option4"
                v-model="lc29_44_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q44_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 46">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(45)"
                id="q45_option1"
                v-model="lc29_45_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q45_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(45)"
                id="q45_option2"
                v-model="lc29_45_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q45_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(45)"
                id="q45_option3"
                v-model="lc29_45_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q45_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(45)"
                id="q45_option4"
                v-model="lc29_45_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q45_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 47">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(46)"
                id="q46_option1"
                v-model="lc29_46_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q46_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(46)"
                id="q46_option2"
                v-model="lc29_46_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q46_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(46)"
                id="q46_option3"
                v-model="lc29_46_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q46_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(46)"
                id="q46_option4"
                v-model="lc29_46_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q46_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 48">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(47)"
                id="q47_option1"
                v-model="lc29_47_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q47_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(47)"
                id="q47_option2"
                v-model="lc29_47_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q47_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(47)"
                id="q47_option3"
                v-model="lc29_47_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q47_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(47)"
                id="q47_option4"
                v-model="lc29_47_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q47_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 49">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(48)"
                id="q48_option1"
                v-model="lc29_48_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q48_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(48)"
                id="q48_option2"
                v-model="lc29_48_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q48_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(48)"
                id="q48_option3"
                v-model="lc29_48_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q48_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(48)"
                id="q48_option4"
                v-model="lc29_48_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q48_option4">4</label>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-if="page == 50">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(49)"
                id="q49_option1"
                v-model="lc29_49_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q49_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(49)"
                id="q49_option2"
                v-model="lc29_49_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q49_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(49)"
                id="q49_option3"
                v-model="lc29_49_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q49_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(49)"
                id="q49_option4"
                v-model="lc29_49_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q49_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 51">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(50)"
                id="q50_option1"
                v-model="lc29_50_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q50_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(50)"
                id="q50_option2"
                v-model="lc29_50_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q50_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(50)"
                id="q50_option3"
                v-model="lc29_50_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q50_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(50)"
                id="q50_option4"
                v-model="lc29_50_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q50_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 52">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(51)"
                id="q51_option1"
                v-model="lc29_51_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q51_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(51)"
                id="q51_option2"
                v-model="lc29_51_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q51_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(51)"
                id="q51_option3"
                v-model="lc29_51_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q51_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(51)"
                id="q51_option4"
                v-model="lc29_51_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q51_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 53">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(52)"
                id="q52_option1"
                v-model="lc29_52_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q52_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(52)"
                id="q52_option2"
                v-model="lc29_52_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q52_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(52)"
                id="q52_option3"
                v-model="lc29_52_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q52_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(52)"
                id="q52_option4"
                v-model="lc29_52_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q52_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 54">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(53)"
                id="q53_option1"
                v-model="lc29_53_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q53_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(53)"
                id="q53_option2"
                v-model="lc29_53_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q53_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(53)"
                id="q53_option3"
                v-model="lc29_53_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q53_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(53)"
                id="q53_option4"
                v-model="lc29_53_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q53_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 55">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(54)"
                id="q54_option1"
                v-model="lc29_54_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q54_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(54)"
                id="q54_option2"
                v-model="lc29_54_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q54_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(54)"
                id="q54_option3"
                v-model="lc29_54_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q54_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(54)"
                id="q54_option4"
                v-model="lc29_54_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q54_option4">4</label>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-if="page == 56">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(55)"
                id="q55_option1"
                v-model="lc29_55_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q55_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(55)"
                id="q55_option2"
                v-model="lc29_55_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q55_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(55)"
                id="q55_option3"
                v-model="lc29_55_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q55_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(55)"
                id="q55_option4"
                v-model="lc29_55_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q55_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 57">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(56)"
                id="q56_option1"
                v-model="lc29_56_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
               <label class="btn btn-outline-primary btn-lg" for="q56_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(56)"
                id="q56_option2"
                v-model="lc29_56_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q56_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(56)"
                id="q56_option3"
                v-model="lc29_56_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q56_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(56)"
                id="q56_option4"
                v-model="lc29_56_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q56_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 58">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(57)"
                id="q57_option1"
                v-model="lc29_57_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q57_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(57)"
                id="q57_option2"
                v-model="lc29_57_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q57_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(57)"
                id="q57_option3"
                v-model="lc29_57_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q57_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(57)"
                id="q57_option4"
                v-model="lc29_57_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q57_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 59">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(58)"
                id="q58_option1"
                v-model="lc29_58_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q58_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(58)"
                id="q58_option2"
                v-model="lc29_58_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q58_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(58)"
                id="q58_option3"
                v-model="lc29_58_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q58_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(58)"
                id="q58_option4"
                v-model="lc29_58_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q58_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 60">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="1"
                :name="questionRadioName(59)"
                id="q59_option1"
                v-model="lc29_59_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q59_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="2"
                :name="questionRadioName(59)"
                id="q59_option2"
                v-model="lc29_59_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q59_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="3"
                :name="questionRadioName(59)"
                id="q59_option3"
                v-model="lc29_59_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q59_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                :value="4"
                :name="questionRadioName(59)"
                id="q59_option4"
                v-model="lc29_59_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q59_option4">4</label>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-if="page == 61">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="1"
                :name="questionRadioName(60)"
                id="q60_option1"
                v-model="lc29_60_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q60_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="2"
                :name="questionRadioName(60)"
                id="q60_option2"
                v-model="lc29_60_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q60_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="3"
                :name="questionRadioName(60)"
                id="q60_option3"
                v-model="lc29_60_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q60_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="4"
                :name="questionRadioName(60)"
                id="q60_option4"
                v-model="lc29_60_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q60_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 62">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="1"
                :name="questionRadioName(61)"
                id="q61_option1"
                v-model="lc29_61_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q61_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="2"
                :name="questionRadioName(61)"
                id="q61_option2"
                v-model="lc29_61_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q61_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="3"
                :name="questionRadioName(61)"
                id="q61_option3"
                v-model="lc29_61_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q61_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="4"
                :name="questionRadioName(61)"
                id="q61_option4"
                v-model="lc29_61_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q61_option4">4</label>
            </td>
          </tr>
          <tr v-if="page == 63">
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="1"
                :name="questionRadioName(62)"
                id="q62_option1"
                v-model="lc29_62_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q62_option1">1</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="2"
                :name="questionRadioName(62)"
                id="q62_option2"
                v-model="lc29_62_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q62_option2">2</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="3"
                :name="questionRadioName(62)"
                id="q62_option3"
                v-model="lc29_62_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q62_option3">3</label>
            </td>
            <td class="text-center">
              <input
                type="radio"
                class="btn-check"
                value="4"
                :name="questionRadioName(62)"
                id="q62_option4"
                v-model="lc29_62_value"
                @change="this.next()"
                 :disabled="!MEASUREMENT.can_edit"
              />
              <label class="btn btn-outline-primary btn-lg" for="q62_option4">4</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="(page == 64 && !this.show_therapy_questions) || page == 67">
      <p v-if="MEASUREMENT.can_edit">
        Vielen Dank für das Ausfüllen des Fragebogens. Klicken Sie bitte auf
        Speichern, um die Befragung abzuschließen.
      </p>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Alle Fragen beantwortet?"
      >
        <button
          v-if="MEASUREMENT.can_edit"
          @click="endQuestionaire()"
          type="button"
          class="btn btn-primary"
        >
          Speichern
        </button>
      </div>
    </div>
    <div class="progress" v-if="page == 0">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 2%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="0"
      >
        2%
    </div> 
    </div>
    <div class="progress" v-if="page == 1">
      <div
        v-if="!this.show_therapy_questions"
        class="progress-bar"
        role="progressbar"
        style="width: 3%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="1"
      >
        3%
      </div>
    </div>
    <div class="progress" v-if="page == 2">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 5%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="2"
      >
        5%
      </div>
    </div>
    <div class="progress" v-if="page == 3">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 6%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="3"
      >
        6%
      </div>
    </div>
    <div class="progress" v-if="page == 4">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 8%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="4"
      >
        8%
      </div>
    </div>
    <div class="progress" v-if="page == 5">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 9%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="5"
      >
        9%
      </div>
    </div>
    <div class="progress" v-if="page == 6">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 11%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="6"
      >
        11%
      </div>
    </div>
    <div class="progress" v-if="page == 7">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 12%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="7"
      >
        12%
      </div>
    </div>
    <div class="progress" v-if="page == 8">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 14%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="8"
      >
        14%
      </div>
    </div>
    <div class="progress" v-if="page == 9">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 15%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="9"
      >
        15%
      </div>
    </div>
    <div class="progress" v-if="page == 10">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 17%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="10"
      >
        17%
      </div>
    </div>
    <div class="progress" v-if="page == 11">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 18%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="11"
      >
        18%
      </div>
    </div>
    <div class="progress" v-if="page == 12">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 20%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="12"
      >
        20%
      </div>
    </div>
    <div class="progress" v-if="page == 13">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 22%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="13"
      >
        22%
      </div>
    </div>
    <div class="progress" v-if="page == 14">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 23%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="14"
      >
        23%
      </div>
    </div>
    <div class="progress" v-if="page == 15">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 25%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="15"
      >
        25%
      </div>
    </div>
    <div class="progress" v-if="page == 16">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 26%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="16"
      >
        26%
      </div>
    </div>
    <div class="progress" v-if="page == 17">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 28%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="17"
      >
        28%
      </div>
    </div>
    <div class="progress" v-if="page == 18">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 29%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="18"
      >
        29%
      </div>
    </div>
    <div class="progress" v-if="page == 19">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 31%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="19"
      >
        31%
      </div>
    </div>
    <div class="progress" v-if="page == 20">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 32%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="20"
      >
        32%
      </div>
    </div>
    <div class="progress" v-if="page == 21">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 34%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="21"
      >
        34%
      </div>
    </div>
    <div class="progress" v-if="page == 22">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 35%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="22"
      >
        35%
      </div>
    </div>
    <div class="progress" v-if="page == 23">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 37%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="23"
      >
        37%
      </div>
    </div>
    <div class="progress" v-if="page == 24">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 38%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="24"
      >
        38%
      </div>
    </div>
    <div class="progress" v-if="page == 25">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 40%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="25"
      >
        40%
      </div>
    </div>
    <div class="progress" v-if="page == 26">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 42%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="26"
      >
        42%
      </div>
    </div>
    <div class="progress" v-if="page == 27">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 43%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="27"
      >
        43%
      </div>
    </div>
    <div class="progress" v-if="page == 28">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 45%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="28"
      >
        45%
      </div>
    </div>
    <div class="progress" v-if="page == 29">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 46%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="29"
      >
        46%
      </div>
    </div>
    <div class="progress" v-if="page == 30">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 48%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="30"
      >
        48%
      </div>
    </div>
    <div class="progress" v-if="page == 31">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 49%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="31"
      >
        49%
      </div>
    </div>
    <div class="progress" v-if="page == 32">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 51%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="32"
      >
        51%
      </div>
    </div>
    <div class="progress" v-if="page == 33">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 52%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="33"
      >
        52%
      </div>
    </div>
    <div class="progress" v-if="page == 34">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 54%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="34"
      >
        54%
      </div>
    </div>
    <div class="progress" v-if="page == 35">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 55%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="35"
      >
        55%
      </div>
    </div>
    <div class="progress" v-if="page == 36">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 57%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="36"
      >
        57%
      </div>
    </div>
    <div class="progress" v-if="page == 37">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 58%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="37"
      >
        58%
      </div>
    </div>
    <div class="progress" v-if="page == 38">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 60%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="38"
      >
        60%
      </div>
    </div>
    <div class="progress" v-if="page == 39">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 62%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="39"
      >
        62%
      </div>
    </div>
    <div class="progress" v-if="page == 40">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 63%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="40"
      >
        63%
      </div>
    </div>
    <div class="progress" v-if="page == 41">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 65%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="41"
      >
        65%
      </div>
    </div>
    <div class="progress" v-if="page == 42">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 66%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="42"
      >
        66%
      </div>
    </div>
    <div class="progress" v-if="page == 43">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 68%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="43"
      >
        68%
      </div>
    </div>
    <div class="progress" v-if="page == 44">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 69%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="44"
      >
        69%
      </div>
    </div>
    <div class="progress" v-if="page == 45">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 71%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="45"
      >
        71%
      </div>
    </div>
    <div class="progress" v-if="page == 46">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 72%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="46"
      >
        72%
      </div>
    </div>
    <div class="progress" v-if="page == 47">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 74%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="47"
      >
        74%
      </div>
    </div>
    <div class="progress" v-if="page == 48">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 75%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="48"
      >
        75%
      </div>
    </div>
    <div class="progress" v-if="page == 49">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 77%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="49"
      >
        77%
      </div>
    </div>
    <div class="progress" v-if="page == 50">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 78%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="50"
      >
        78%
      </div>
    </div>
    <div class="progress" v-if="page == 51">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 80%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="51"
      >
        80%
      </div>
    </div>
    <div class="progress" v-if="page == 52">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 82%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="52"
      >
        82%
      </div>
    </div>
    <div class="progress" v-if="page == 53">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 83%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="53"
      >
        83%
      </div>
    </div>
    <div class="progress" v-if="page == 54">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 85%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="54"
      >
        85%
      </div>
    </div>
    <div class="progress" v-if="page == 55">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 86%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="55"
      >
        86%
      </div>
    </div>
    <div class="progress" v-if="page == 56">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 88%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="56"
      >
        88%
      </div>
    </div>
    <div class="progress" v-if="page == 57">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 89%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="57"
      >
        89%
      </div>
    </div>
    <div class="progress" v-if="page == 58">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 91%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="58"
      >
        91%
      </div>
    </div>
    <div class="progress" v-if="page == 59">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 92%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="59"
      >
        92%
      </div>
    </div>
    <div class="progress" v-if="page == 60">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 94%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="60"
      >
        94%
      </div>
    </div>
    <div class="progress" v-if="page == 61">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 95%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="61"
      >
        95%
      </div>
    </div>
    <div class="progress" v-if="page == 62">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 97%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="62"
      >
        97%
      </div>
    </div>
    <div class="progress" v-if="page == 63">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 98%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="63"
      >
        98%
      </div>
    </div>
    <div class="progress" v-if="page == 64 && !this.show_therapy_questions">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 100%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="64"
      >
        100%
      </div>
    </div>
    <div class="progress" v-if="page == 64 && this.show_therapy_questions">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 98%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="64"
      >
        98%
      </div>
    </div>
    <div class="progress" v-if="page == 65 && this.show_therapy_questions">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 99%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="65"
      >
        99%
      </div>
    </div>
    <div class="progress" v-if="page == 66 && this.show_therapy_questions">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 99%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="65"
      >
        99%
      </div>
    </div>
    <div class="progress" v-if="page == 67 && this.show_therapy_questions">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 99%"
        aria-valuemax="66"
        aria-valuemin="0"
        :aria-valuenow="65"
      >
        100%
      </div>
    </div>
    <notification-modal
        ref="notificationModal"
            :show="notificationModalVisible"
            @modalClosed="notificationModalClosed"
            :notification="NOTIFICATION"
            :showSaveButton="is_2"
            @saveButtonClicked="saveButtonClicked"
            v-if="notificationModalVisible && NOTIFICATION != ''">

        </notification-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import router from "@/router";
import axios from "@/core/api";
import NotificationModal from '@/modules/admins/components/NotificationModal'

export default defineComponent({
  data() {
    return {
      page: 0,
      notificationModalVisible: false,
      is_2: localStorage.getItem('user_type')  ? JSON.parse(localStorage.getItem('user_type')).enum == 2: false,
      show_therapy_questions: localStorage.getItem('show_therapy_questions') == "true"
    };
  },
  components: {
    NotificationModal
  },
  computed: {
    ...mapGetters({
      MEASUREMENT: "Measurements/MEASUREMENT",
      FK_USER: "Measurements/FK_USER",
      VALIDATION: "Measurements/VALIDATION",
      NOTIFICATION: 'Admin/NOTIFICATION'
      //Bei zusätzlichen Fragen anpassen: 
      //Header Seitennummer
      //Seitenbereich für Next Button
      //Seitenzahl für "Vielen Dank für die Teilnahme..."
    }),
    c30_1_value: {
      get() {
        return this.MEASUREMENT.values[0];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 0,
          value: value,
        });
      },
    },
    c30_2_value: {
      get() {
        return this.MEASUREMENT.values[1];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 1,
          value: value,
        });
      },
    },
    c30_3_value: {
      get() {
        return this.MEASUREMENT.values[2];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 2,
          value: value,
        });
      },
    },
    c30_4_value: {
      get() {
        return this.MEASUREMENT.values[3];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 3,
          value: value,
        });
      },
    },
    c30_5_value: {
      get() {
        return this.MEASUREMENT.values[4];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 4,
          value: value,
        });
      },
    },
    c30_6_value: {
      get() {
        return this.MEASUREMENT.values[5];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 5,
          value: value,
        });
      },
    },
    c30_7_value: {
      get() {
        return this.MEASUREMENT.values[6];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 6,
          value: value,
        });
      },
    },
    c30_8_value: {
      get() {
        return this.MEASUREMENT.values[7];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 7,
          value: value,
        });
      },
    },
    c30_9_value: {
      get() {
        return this.MEASUREMENT.values[8];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 8,
          value: value,
        });
      },
    },
    c30_10_value: {
      get() {
        return this.MEASUREMENT.values[9];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 9,
          value: value,
        });
      },
    },
    c30_11_value: {
      get() {
        return this.MEASUREMENT.values[10];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 10,
          value: value,
        });
      },
    },
    c30_12_value: {
      get() {
        return this.MEASUREMENT.values[11];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 11,
          value: value,
        });
      },
    },
    c30_13_value: {
      get() {
        return this.MEASUREMENT.values[12];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 12,
          value: value,
        });
      },
    },
    c30_14_value: {
      get() {
        return this.MEASUREMENT.values[13];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 13,
          value: value,
        });
      },
    },
    c30_15_value: {
      get() {
        return this.MEASUREMENT.values[14];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 14,
          value: value,
        });
      },
    },
    c30_16_value: {
      get() {
        return this.MEASUREMENT.values[15];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 15,
          value: value,
        });
      },
    },
    c30_17_value: {
      get() {
        return this.MEASUREMENT.values[16];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 16,
          value: value,
        });
      },
    },
    c30_18_value: {
      get() {
        return this.MEASUREMENT.values[17];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 17,
          value: value,
        });
      },
    },
    c30_19_value: {
      get() {
        return this.MEASUREMENT.values[18];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 18,
          value: value,
        });
      },
    },
    c30_20_value: {
      get() {
        return this.MEASUREMENT.values[19];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 19,
          value: value,
        });
      },
    },
    c30_21_value: {
      get() {
        return this.MEASUREMENT.values[20];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 20,
          value: value,
        });
      },
    },
    c30_22_value: {
      get() {
        return this.MEASUREMENT.values[21];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 21,
          value: value,
        });
      },
    },
    c30_23_value: {
      get() {
        return this.MEASUREMENT.values[22];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 22,
          value: value,
        });
      },
    },
    c30_24_value: {
      get() {
        return this.MEASUREMENT.values[23];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 23,
          value: value,
        });
      },
    },
    c30_25_value: {
      get() {
        return this.MEASUREMENT.values[24];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 24,
          value: value,
        });
      },
    },
    c30_26_value: {
      get() {
        return this.MEASUREMENT.values[25];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 25,
          value: value,
        });
      },
    },
    c30_27_value: {
      get() {
        return this.MEASUREMENT.values[26];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 26,
          value: value,
        });
      },
    },
    c30_28_value: {
      get() {
        return this.MEASUREMENT.values[27];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 27,
          value: value,
        });
      },
    },
    c30_29_value: {
      get() {
        return this.MEASUREMENT.values[28];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 28,
          value: value,
        });
      },
    },
    c30_30_value: {
      get() {
        return this.MEASUREMENT.values[29];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 29,
          value: value,
        });
      },
    },
    lc29_31_value: {
      get() {
        return this.MEASUREMENT.values[30];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 30,
          value: value,
        });
      },
    },
    lc29_32_value: {
      get() {
        return this.MEASUREMENT.values[31];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 31,
          value: value,
        });
      },
    },
    lc29_33_value: {
      get() {
        return this.MEASUREMENT.values[32];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 32,
          value: value,
        });
      },
    },
    lc29_34_value: {
      get() {
        return this.MEASUREMENT.values[33];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 33,
          value: value,
        });
      },
    },
    lc29_35_value: {
      get() {
        return this.MEASUREMENT.values[34];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 34,
          value: value,
        });
      },
    },
    lc29_36_value: {
      get() {
        return this.MEASUREMENT.values[35];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 35,
          value: value,
        });
      },
    },
    lc29_37_value: {
      get() {
        return this.MEASUREMENT.values[36];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 36,
          value: value,
        });
      },
    },
    lc29_38_value: {
      get() {
        return this.MEASUREMENT.values[37];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 37,
          value: value,
        });
      },
    },
    lc29_39_value: {
      get() {
        return this.MEASUREMENT.values[38];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 38,
          value: value,
        });
      },
    },
    lc29_40_value: {
      get() {
        return this.MEASUREMENT.values[39];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 39,
          value: value,
        });
      },
    },
    lc29_41_value: {
      get() {
        return this.MEASUREMENT.values[40];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 40,
          value: value,
        });
      },
    },
    lc29_42_value: {
      get() {
        return this.MEASUREMENT.values[41];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 41,
          value: value,
        });
      },
    },
    lc29_43_value: {
      get() {
        return this.MEASUREMENT.values[42];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 42,
          value: value,
        });
      },
    },
    lc29_44_value: {
      get() {
        return this.MEASUREMENT.values[43];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 43,
          value: value,
        });
      },
    },
    lc29_45_value: {
      get() {
        return this.MEASUREMENT.values[44];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 44,
          value: value,
        });
      },
    },
    lc29_46_value: {
      get() {
        return this.MEASUREMENT.values[45];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 45,
          value: value,
        });
      },
    },
    lc29_47_value: {
      get() {
        return this.MEASUREMENT.values[46];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 46,
          value: value,
        });
      },
    },
    lc29_48_value: {
      get() {
        return this.MEASUREMENT.values[47];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 47,
          value: value,
        });
      },
    },
    lc29_49_value: {
      get() {
        return this.MEASUREMENT.values[48];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 48,
          value: value,
        });
      },
    },
    lc29_50_value: {
      get() {
        return this.MEASUREMENT.values[49];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 49,
          value: value,
        });
      },
    },
    lc29_51_value: {
      get() {
        return this.MEASUREMENT.values[50];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 50,
          value: value,
        });
      },
    },
    lc29_52_value: {
      get() {
        return this.MEASUREMENT.values[51];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 51,
          value: value,
        });
      },
    },
    lc29_53_value: {
      get() {
        return this.MEASUREMENT.values[52];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 52,
          value: value,
        });
      },
    },
    lc29_54_value: {
      get() {
        return this.MEASUREMENT.values[53];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 53,
          value: value,
        });
      },
    },
    lc29_55_value: {
      get() {
        return this.MEASUREMENT.values[54];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 54,
          value: value,
        });
      },
    },
    lc29_56_value: {
      get() {
        return this.MEASUREMENT.values[55];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 55,
          value: value,
        });
      },
    },
    lc29_57_value: {
      get() {
        return this.MEASUREMENT.values[56];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 56,
          value: value,
        });
      },
    },
    lc29_58_value: {
      get() {
        return this.MEASUREMENT.values[57];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 57,
          value: value,
        });
      },
    },
    lc29_59_value: {
      get() {
        return this.MEASUREMENT.values[58];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 58,
          value: value,
        });
      },
    },
    lc29_60_value: {
      get() {
        return this.MEASUREMENT.values[59];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 59,
          value: value,
        });
      },
    },
    lc29_60_text_value: {
      get() {
        return this.MEASUREMENT.lc29_60_text;
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_LC29_60_TEXT_VALUE", {
          value: value,
        });
      },
    },
    lc29_61_value: {
      get() {
        return this.MEASUREMENT.values[60];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 60,
          value: value,
        });
      },
    },
    lc29_61_text_value: {
      get() {
        return this.MEASUREMENT.lc29_61_text;
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_LC29_61_TEXT_VALUE", {
          value: value,
        });
      },
    },
    lc29_62_value: {
      get() {
        return this.MEASUREMENT.values[61];
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_VALUE", {
          index: 61,
          value: value,
        });
      },
    },
    lc29_62_text_value: {
      get() {
        return this.MEASUREMENT.lc29_62_text;
      },
      set(value) {
        this.$store.commit("Measurements/SET_MEASUREMENT_LC29_62_TEXT_VALUE", {
          value: value,
        });
      },
    },
    therapy1: {
      get() {
        return this.MEASUREMENT.therapy_1 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_1", {
          value: value,
        });
      },
    },
    therapy2: {
      get() {
        return this.MEASUREMENT.therapy_2 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_2", {
          value: value,
        });
      },
    },
    therapy3: {
      get() {
        return this.MEASUREMENT.therapy_3 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_3", {
          value: value,
        });
      },
    },
    therapy4: {
      get() {
        return this.MEASUREMENT.therapy_4 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_4", {
          value: value,
        });
      },
    },
    therapy5: {
      get() {
        return this.MEASUREMENT.therapy_5 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_5", {
          value: value,
        });
      },
    },
    therapy6: {
      get() {
        return this.MEASUREMENT.therapy_6 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_6", {
          value: value,
        });
      },
    },
    therapy7: {
      get() {
        return this.MEASUREMENT.therapy_7 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_7", {
          value: value,
        });
      },
    },
    therapy8: {
      get() {
        return this.MEASUREMENT.therapy_8 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_8", {
          value: value,
        });
      },
    },
    therapy9: {
      get() {
        return this.MEASUREMENT.therapy_9 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_9", {
          value: value,
        });
      },
    },
    therapy10: {
      get() {
        return this.MEASUREMENT.therapy_10 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_10", {
          value: value,
        });
      },
    },
    therapy11: {
      get() {
        return this.MEASUREMENT.therapy_11 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_11", {
          value: value,
        });
      },
    },
    therapy12: {
      get() {
        return this.MEASUREMENT.therapy_12 == 1;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_12", {
          value: value,
        });
      },
    },
    therapy13_text: {
      get() {
        return this.MEASUREMENT.therapy_13_text;
      },
      set(value) {
        this.$store.commit("Measurements/SET_THERAPY_13_TEXT", {
          value: value,
        });
      },
    },

  
  },
  methods: {
    save() {
      this.$store.dispatch("Measurements/SAVE", {
        measurement: this.MEASUREMENT,
      });
    },
    jumpToFirstUnanswerdQuestion() {
      //let info = [0, 31]
      this.$store.commit("Measurements/VALIDATE_MEASUREMENT", {measurement: this.MEASUREMENT, show_therapy_questions: this.show_therapy_questions});

      for(let i = 1; i <= 30; i++){
      let index_str = "q" + i + "ok"
        if (!this.VALIDATION[index_str]) {
          this.page = i;
          return;
        }
      }
      if (!this.VALIDATION.q31ok && this.page < 31) {
        this.page = 31;
        return;
      }
      if (!this.VALIDATION.q31ok && this.page == 31) {
        this.page = 32;
        return;
      }

      for(let i = 32; i <= 59; i++){
        let index_str = "q" + i + "ok"
        if (!this.VALIDATION[index_str]) {
          this.page = i + 1;
          return;
        }
      }

      this.page += 1;
    },
    async endQuestionaire() {
      this.$store.commit("Measurements/VALIDATE_MEASUREMENT", {measurement: this.MEASUREMENT, show_therapy_questions: this.show_therapy_questions});
      if (Object.values(this.VALIDATION).every((tru) => tru)) {
        this.save();
        var url = "/measurements/redirect";
      const response = await axios.get(url);
      if (response.status == 200) {
        router.push(response.data.url);
      }
      } else {
        this.$store.commit("Admin/SET_NOTIFICATION", "Es gibt noch unbeantwortete Fragen.")
        this.showNotificationModal();
        for(let i = 1; i <= 30; i++){
          let index_str = "q" + i + "ok"
          if (!this.VALIDATION[index_str]) {
            this.page = i;
            return;
          }
        }
        for(let i = 32; i <= 59; i++){
          let index_str = "q" + i + "ok"
          if (!this.VALIDATION[index_str]) {
            this.page = i + 1;
            return;
          }
        }

        return;
      }
    },
    async saveButtonClicked() { //skip validation
      this.save();
        var url = "/measurements/redirect";
        const response = await axios.get(url);
      if (response.status == 200) {
        router.push(response.data.url);
      }
    },

    questionRadioName(id) {
      return "radio-" + id;
    },
    next() {
      setTimeout(() => this.jumpToFirstUnanswerdQuestion(), 300);
    },
    nextQuestion() {
      if (this.page < 64) {
        this.page += 1;
        //this.save();
      }else if(this.page < 76 && this.show_therapy_questions){
        this.page += 1;
      }
    },
    previous() {
      if (this.page > 0) {
        this.page -= 1;
        //this.save();
      }
    },
    notificationModalClosed(){
            this.notificationModalVisible = false
            this.$store.commit("Admin/SET_NOTIFICATION", "")
        },
        showNotificationModal(){
            this.notificationModalVisible = true
        }
  },
});
</script>
<style scoped>
.right {
  text-align: right;
}
.radiomaxwidth {
  max-width: 10px;
}
</style>